import "./modalBasic.css";

function ModalBasic({
  setIsModalVisible,
  modalChoiceHandler,
  modalPrimaryMsg,
  modalSecondaryMsg,
  modalPrimaryBtnTxt,
  modalSecondaryBtnTxt,
}) {
  const toggleVisibility = () => {
    setIsModalVisible(false);
  };

  return (
    <div className="darkBG" onClick={() => toggleVisibility()}>
      <div className="basicModalDialogBox">
        {modalPrimaryMsg}
        <div className="basicModalSecondaryText">{modalSecondaryMsg}</div>
        <div className="basicModalActionRow">
          <span
            className="basicModalSwitchBtn"
            onClick={() => modalChoiceHandler(modalPrimaryBtnTxt)}
          >
            {modalPrimaryBtnTxt}
          </span>
          <span
            className="basicModalCancelBtn"
            onClick={() => modalChoiceHandler(modalSecondaryBtnTxt)}
          >
            {modalSecondaryBtnTxt}
          </span>
        </div>
      </div>
    </div>
  );
}
export default ModalBasic;
