import { useState } from "react";
import "./productSizes.css";

import ProductSizeOptionBox from "./ProductSizeOptionBox";
function ProductSizes({ sizes, setSelectedSize, selectedSize }) {
  const [optionsLabel, setOptionsLabel] = useState("Choose Size");

  return (
    <div className="productSizesContainer">
      {optionsLabel} :
      {sizes &&
        sizes.map((item, idx) => (
          <ProductSizeOptionBox
            size={item.size}
            key={idx}
            isSelected={item.size === selectedSize}
            setSelectedSize={setSelectedSize}
          />
        ))}
    </div>
  );
}
export default ProductSizes;
