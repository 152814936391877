import { useLocation, useNavigate } from "react-router-dom";
import { useAnlyParams } from "../../hooks/useAnlyParams";
import "./forgotPasswordLine.css";

function ForgotPasswordLine() {
  const navigate = useNavigate();
  const location = useLocation();
  const analyParamsCB = useAnlyParams();

  const resetPasswordHandler = () => {
    analyParamsCB({
      type: "click",
      pl: {
        pn: location.pathname,
        clk: "resetPasswordLogin",
        //field: JSON.stringify({ email }),
      },
    });
    navigate("/resetpassword");
  };
  return (
    <div className="forgotPasswordRow">
      Forgot Password ?{" "}
      <div className="passResetLink" onClick={() => resetPasswordHandler()}>
        {" "}
        Reset here{" "}
      </div>
    </div>
  );
}

export default ForgotPasswordLine;
