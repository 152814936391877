import BackButton from "../../components/ui/BackButton";
import "./howItWorks.css";
import PlaceOrderImg from "./palceOrder.png";
import MerchantShopImg from "./merchantShop.png";
import PayOnlineImg from "./payOnline.png";
import CurverArrowImg from "./curvedArrow.jpg";
import TruckImg from "./truckImage.png";
import ReturnsImg from "./returnsIcon.png";
import NewProductImg from "./newProductImg.png";

function HowItWorks() {
  return (
    <div className="howItWorksPageContainer">
      <BackButton />
      <div className="howItWorksHeading">
        How do we sell at such low prices ?
      </div>
      <div className="howItWorksSubHeading">
        We work with sellers registered with us to come up with best prices for
        you. Here is an overview:
      </div>
      <div className="howItWorksContentsContainer">
        <div className="howItWorksColumnSmall">
          <img src={PlaceOrderImg} className="howItWorksSmallIcon" />
          <div className="howItWorksColWriteUpContainer">
            <div className="howItWorksHeadingContainer">
              <div className="howItWorksNumberBubble">1</div>
              <div className="howItWorksWriteupHeading">
                You place the order
              </div>
            </div>
            <div className="howItWorksContent">
              Browse through our products and order what you like to buy <br />
              <br /> Note: You don’t pay anything yet!
            </div>
          </div>
        </div>
        <div className="howItWorksColumnLarge">
          <img src={MerchantShopImg} className="howItWorksSmallIcon" />
          <div className="howItWorksColWriteUpContainer">
            <div className="howItWorksHeadingContainer">
              <div className="howItWorksNumberBubble">2</div>
              <div className="howItWorksWriteupHeading">
                We find the Merchant ready to sell
              </div>
            </div>
            <div className="howItWorksContent">
              Once we have found the merchant ready to sell , we let you know
              asap. <br />
              <br /> If no merchant accepts within 24hours ,we send you an email
              to let you know the status. (you can choose to wait for 24 hours
              more )
            </div>
          </div>
        </div>
        <div className="howItWorksColumnSmall">
          <div className="howItworksIconsContainer">
            <img src={PayOnlineImg} className="howItWorksSmallInlineIcon" />
            <img src={CurverArrowImg} className="howItWorksSmallInlineIcon" />
            <img src={TruckImg} className="howItWorksTruckIcon" />
          </div>
          <div className="howItWorksColWriteUpContainer">
            <div className="howItWorksHeadingContainer">
              <div className="howItWorksNumberBubble">3</div>
              <div className="howItWorksWriteupHeading">
                You Accept & Pay; We Ship
              </div>
            </div>
            <div className="howItWorksContent">
              You can Accept the order or Decline(No penalty for declining ). If
              you Accept the order , you will be asked to make a payment online.
              <br />
              <br /> We will notify the Merchant and they will ship you the
              product.
            </div>
          </div>
        </div>
      </div>
      <div className="extraFeaturesContainer">
        <div className="extraFeaturesRow">
          <img src={ReturnsImg} className="returnsImgIcon" />
          <span>Free 30 Days Return </span>
        </div>
        <div className="extraFeaturesRow">
          <img src={NewProductImg} className="returnsImgIcon" />
          <span>All products sold are new products</span>
        </div>
      </div>
    </div>
  );
}
export default HowItWorks;
