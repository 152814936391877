import { useContext, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { useLocation, useNavigate } from "react-router-dom";
import { useAnlyParams } from "../../hooks/useAnlyParams";
import { useServerAPICalls } from "../../hooks/useServerAPICalls";
import { UserContext } from "../../store/userContext";
import ForgotPasswordLine from "./ForgotPasswordLine";
import "./signupBox.css";

function SignupBox({ switchLoginSignup, referrer }) {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [pass, setPass] = useState("");
  const [confirm, setConfirm] = useState("");
  const [captcha, setCaptcha] = useState(null);
  const [isErrorSubmission, setIsErrorSubmission] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const analyParamsCB = useAnlyParams();

  const serverApiCallback = useServerAPICalls();
  const { setUser } = useContext(UserContext);

  function onCaptchaVerification(value) {
    //console.log("Captcha value:", value);
    setCaptcha(value);
  }

  const sendUserDetails = async () => {
    //console.log("Going to send order details");
    const data = await serverApiCallback("post", "user", {
      captcha,
      user: { name, email, pass, referrer },
    });
    //console.log("The SU data returned is ", data.data);
    if (data.isError) {
      console.log("Error: ", data.msg);
      setIsErrorSubmission(true);
      return;
    }
    navigate("/suvalidate");
    // setUser(data.data);
    // if (referrer) {
    //   alert("Sign up Successfull! ");
    //   navigate("/");
    //}
  };

  const signupHandler = () => {
    if (name === "") {
      alert("Please Enter Name");
      return;
    }
    if (email === "") {
      alert("Please Enter Email");
      return;
    }
    if (pass === "") {
      alert("Please Enter Password");
      return;
    }
    if (confirm === "") {
      alert("Please Confirm Password");
      return;
    }
    if (confirm !== pass) {
      alert("The Passwords Dont Match");
      return;
    }
    if (!captcha) {
      alert("Please verify the captcha");
      return;
    }
    sendUserDetails();
  };

  return (
    <div className="signupContainer">
      <div className="signupHeader">Sign Up</div>

      <div className="signupDetailsRow">
        <div className="signupRowLabel">Name</div>
        <input
          className="signupRowInput"
          onChange={(e) => {
            setName(e.target.value);
          }}
        />
      </div>
      <div className="signupDetailsRow">
        <div className="signupRowLabel">Email</div>
        <input
          className="signupRowInput"
          type="email"
          onChange={(e) => {
            setEmail(e.target.value);
          }}
        />
      </div>
      <div className="signupDetailsRow">
        <div className="signupRowLabel">Password</div>
        <input
          className="signupRowInput"
          type="password"
          onChange={(e) => {
            setPass(e.target.value);
          }}
        />
      </div>
      <div className="signupDetailsRow">
        <div className="signupRowLabel" type="password">
          Confirm Password
        </div>
        <input
          className="signupRowInput"
          type="password"
          onChange={(e) => {
            setConfirm(e.target.value);
          }}
        />
      </div>
      <div className="signupCaptchaBox">
        <ReCAPTCHA
          //sitekey="6LdvwIckAAAAACvnmmU2nv9SjTpjebRw2dzNT6Mb"
          //sitekey="6Ldueu0kAAAAALNNOmehsaJc-Ftfh1IoqhvvzTNZ"
          sitekey={
            process.env.NODE_ENV === "development"
              ? "6Ldueu0kAAAAALNNOmehsaJc-Ftfh1IoqhvvzTNZ"
              : "6LdiF_snAAAAAAA3Xa9tGlL-auCc8CNTLI-17f9h"
          }
          onChange={onCaptchaVerification}
        />
      </div>
      <div className="termsRowSignup">
        By Signing up you are agreeing to the
        <span
          className="termsLinkSignup"
          onClick={() => {
            analyParamsCB({
              type: "click",
              pl: {
                pn: location.pathname,
                clk: "termsLinkSignPage",
              },
            });
            navigate("/terms");
          }}
        >
          {" "}
          Terms and Conditions
        </span>
      </div>
      {isErrorSubmission && (
        <div className="signupError">
          {" "}
          Error! Email or Password Incorrect ! Please try with another value
        </div>
      )}
      <div className="signupBtnRow">
        <div
          className="singupBtn"
          onClick={() => {
            analyParamsCB({
              type: "click",
              pl: {
                pn: location.pathname,
                clk: "signupBtn",
                field: email,
              },
            });
            signupHandler();
          }}
        >
          Sign up
        </div>
      </div>
      <div className="loginInsteadRow">
        Already have an account ?{" "}
        <div
          className="loginSignupLink"
          onClick={() => {
            analyParamsCB({
              type: "click",
              pl: {
                pn: location.pathname,
                clk: "switchToLoginBoxAccountPage",
              },
            });
            switchLoginSignup((current) => !current);
          }}
        >
          Login
        </div>
        instead
        <ForgotPasswordLine />
      </div>
    </div>
  );
}

export default SignupBox;
