import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import LoginBox from "../../components/account/LoginBox";
import SignupBox from "../../components/account/SignupBox";
import { useServerAPICalls } from "../../hooks/useServerAPICalls";
import { UserContext } from "../../store/userContext";
import "./invite.css";

function Invite() {
  const [tryLogin, setTryLogin] = useState(true);
  const [authenticated, setAuthenticated] = useState(false);
  const { user, getUserDetailsFromStorage, setUser } = useContext(UserContext);
  const params = useParams();

  const serverApiCallback = useServerAPICalls();

  // useEffect(() => {
  //   getUserDetailsFromStorage();
  // }, []);

  const authenticateToken = async () => {
    const data = await serverApiCallback("post", "user/auth", {
      token: user || user.token,
    });

    //console.log("Authenticated Data : ", data.data);
    if (data.data === true) {
      setAuthenticated(true);
    }
    //setUser({ ...data.data, token: data.data.id }); dont reset the data as it will cause the loop
  };

  useEffect(() => {
    if (user && user !== "delete") {
      //console.log("The user details currently are ", user);
      authenticateToken();
    } else {
      setAuthenticated(false);
    }
  }, [user]);

  const logoutHandler = async () => {
    setUser("delete");
  };

  return (
    <div className="invitePageContainer">
      {authenticated ? (
        <div className="invitePageLoginMessageHeading">
          <div className="invitePageLoginMessage">
            You are already logged in! Please Logout for new account signup.
          </div>
          <div className="invitePageLogoutBtn" onClick={() => logoutHandler()}>
            Log Out
          </div>
        </div>
      ) : tryLogin ? (
        <>
          <div className="invitePageSignupMessageHeading">
            You have been invited! You and your friend will get £15 each after
            your first order is confirmed!
          </div>
          <SignupBox switchLoginSignup={setTryLogin} referrer={params.id} />
        </>
      ) : (
        <LoginBox switchLoginSignup={setTryLogin} />
      )}
    </div>
  );
}
export default Invite;
