import { useState } from "react";
import { createContext } from "react";

export const ShoesContext = createContext();

// NOTE: PLEASE READ
// THIS is not an IDEAL way, this is a temporary fix. We should scrape using the context to store the filter values, rather use the parameters in the url.

function ShoesContextProvider({ children }) {
  const [genderCtx, setGenderCtx] = useState();
  const [sizeCtx, setSizeCtx] = useState();
  const [colourCtx, setColourCtx] = useState();

  const value = {
    genderCtx,
    setGenderCtx,
    sizeCtx,
    setSizeCtx,
    colourCtx,
    setColourCtx,
  };
  return (
    <ShoesContext.Provider value={value}>{children}</ShoesContext.Provider>
  );
}

export default ShoesContextProvider;
