import "./sellerProduct.css";

// function SellerProduct() {
//   const params = useParams();
//   const _id = params.id;
//   return <div>SellerProduct {_id}</div>;
// }
// export default SellerProduct;

import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useEffect } from "react";
import { useState } from "react";
import BackButton from "../../../components/ui/BackButton";
import { useServerAPICalls } from "../../../hooks/useServerAPICalls";
import ProductDesciptionSection from "../../../components/productDetails/ProductDesciptionSection";
import { useAnlyParams } from "../../../hooks/useAnlyParams";
import { prodData } from "../sellerHome/SellerHome";
import SellerProductDesciptionSection from "../../../demoTool/sellerProduct/SellerProductDesciptionSection";

function SellerProduct() {
  const [prod, setProd] = useState();
  const [mainImg, setMainImg] = useState();
  const params = useParams();
  const navigate = useNavigate();

  const serverApiCallback = useServerAPICalls();
  const location = useLocation();
  const analyParamsCB = useAnlyParams();

  const setMainImgHanlder = (img) => {
    // console.log("Set Main Image to ", img);
    setMainImg(img);
  };

  useEffect(() => {
    setProd(prodData.filter((p) => p._id === params.id)[0]);

    window.scrollTo(0, 0); // apparenttly this is needed otherwise the page may open a bit below based on where it was in the previous screen
  }, [params]);

  useEffect(() => {
    prod &&
      setMainImg(
        (process.env.NODE_ENV === "development"
          ? "http://192.168.1.133:5566"
          : "https://lotcheaper.com") +
          "/uploads/prodImages/prod_" +
          prod._id +
          "/" +
          prod._id +
          "_1.jpg"
      );
  }, [prod]);

  return (
    <div className="productDetailsPageContainer">
      <BackButton />
      {prod && (
        <div className="productMainContainer">
          <div className="productImageAndDescBox">
            <div className="prodDescDiscountAmt">
              {"£ " + Number(prod.origPrice - prod.offerPrice).toLocaleString()}{" "}
              Off
            </div>
            <img src={mainImg} className="mainProdImage" />
            <div className="bottomImgsContainer">
              {[...Array(prod.images_cnt ? prod.images_cnt : 4)].map(
                (item, idx) => (
                  <div
                    key={idx}
                    className="sellerProductBottomImg"
                    onClick={() => {
                      setMainImgHanlder(
                        (process.env.NODE_ENV === "development"
                          ? "http://192.168.1.133:5566"
                          : "https://lotcheaper.com") +
                          "/uploads/prodImages/prod_" +
                          prod._id +
                          "/" +
                          prod._id +
                          "_" +
                          (idx + 1) +
                          ".jpg"
                      );
                      analyParamsCB({
                        type: "click",
                        pl: {
                          pn: location.pathname,
                          clk: "prodBtmImg",
                          field: "img" + (idx + 1),
                        },
                      });
                    }}
                  >
                    <img
                      className="bottomImg"
                      src={
                        prod
                          ? (process.env.NODE_ENV === "development"
                              ? "http://192.168.1.133:5566"
                              : "https://lotcheaper.com") +
                            "/uploads/prodImages/prod_" +
                            prod._id +
                            "/" +
                            prod._id +
                            "_" +
                            (idx + 1) +
                            ".jpg"
                          : ""
                      }
                    />
                  </div>
                )
              )}
            </div>
            <div className="imgBottoHelpText">click to select </div>
          </div>
          <div className="productImageAndDescBox">
            <SellerProductDesciptionSection prod={prod} />
          </div>
          <div id="productLongDescriptionSection">
            <h4> Product Details </h4>
            {prod && <div>{prod.description}</div>}
          </div>
        </div>
      )}
    </div>
  );
}

export default SellerProduct;
