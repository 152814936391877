import { useEffect, useState } from "react";
import ProductVariantItem from "./ProductVariantItem";
import "./productVariants.css";
import { useServerAPICalls } from "../../../hooks/useServerAPICalls";

function ProductVariants({ variantId, prodId }) {
  const [variants, setVariants] = useState();
  const serverApiCallback = useServerAPICalls();
  const getProdVariants = async () => {
    if (variantId) {
      const data = await serverApiCallback(
        "get",
        "products/variants/" + variantId,
        {}
      );
      //console.log("The Variants data Returned is ", data.data);
      setVariants(data.data.filter((prod) => prod._id !== prodId));
    }
  };
  useEffect(() => {
    //console.log("Varinat Id is ", variantId);

    getProdVariants();
  }, [variantId, prodId]);
  return (
    variantId && (
      <div className="productVariantContainer">
        {variants &&
          variants.map((v, idx) => <ProductVariantItem prod={v} key={idx} />)}
      </div>
    )
  );
}
export default ProductVariants;
