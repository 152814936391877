import { useLocation, useNavigate } from "react-router-dom";
import { useAnlyParams } from "../../hooks/useAnlyParams";
import "./compactProductBox.css";

function CompactProductBox({ width, prod }) {
  const analyParamsCB = useAnlyParams();
  const location = useLocation();
  const navigate = useNavigate();
  return (
    <div
      style={{
        width: width,
        height: width > 175 ? "340px" : "320px",
      }}
      className="compactProductBoxContainer"
    >
      <span
        className="compactProductImageLinkNoDecoration"
        onClick={() => {
          analyParamsCB({
            type: "click",
            pl: {
              pn: location.pathname,
              clk: "imgProdHome",
              field: JSON.stringify(prod.name),
            },
          });
          navigate("/product/" + prod._id);
        }}
      >
        <img
          className="compactProductBoxImg"
          style={{
            maxWidth: width,
            maxHeight: 320 * 0.9,
          }}
          src={
            (process.env.NODE_ENV === "development"
              ? "http://192.168.1.133:5566"
              : "https://comebuyin.com") +
            "/uploads/prodImages/prod_" +
            prod.productId +
            "/" +
            prod.productId +
            "_1.jpg"
          }
          alt={
            "http://192.168.1.133:5566/uploads/prodImages/prod_" +
            prod.productId +
            "/" +
            prod.productId +
            "_1.jpg"
          }
        />
      </span>
      <span
        className="prodNameLink"
        onClick={() => {
          analyParamsCB({
            type: "click",
            pl: {
              pn: location.pathname,
              clk: "prodTitleHome",
              field: prod.name,
            },
          });
          navigate("/product/" + prod._id);
        }}
      >
        <div className="compactProdBoxProdName">
          {prod.name.length > 50 ? prod.name.slice(0, 60) + "..." : prod.name}
        </div>
      </span>
      <div className="compactProductPriceRow">
        <span className="compactProductOrigPrice">£{prod.origPrice}</span>
        <span className="compactProductOfferPrice">
          £
          {prod.cbdeals &&
          Date.parse(prod.cbdeals.cbExpiryTime) - Date.now() > 0
            ? prod.cbdeals.cbPrice
            : prod.offerPrice}
        </span>
      </div>
    </div>
  );
}
export default CompactProductBox;
