import "./App.css";
import Footer from "./components/footer/Footer";
import Header from "./components/header/Header";
import Home from "./screens/home/Home";
import {
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import Category from "./screens/category/Category";
import { useContext, useEffect } from "react";
import Cart from "./screens/cart/Cart";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Checkout from "./screens/checkout/Checkout";
import OrderConfirmation from "./screens/orderConfirmation/OrderConfirmation";
import ProductDetails from "./screens/productDetails/ProductDetails";
import SearchScreen from "./screens/search/SearchScreen";
import AccountScreen from "./screens/account/AccountScreen";
import ContactUsScreen from "./screens/contact/ContactUsScreen";
import SellerContactUsScreen from "./screens/sellerContact/SellerContactUsScreen";
import AllCategories from "./screens/allCategories/AllCategories";
import HowItWorks from "./screens/howItWorks/HowItWorks";
import Invite from "./screens/invite/Invite";
import { useAnlyParams } from "./hooks/useAnlyParams";
import { CookieAccptContext } from "./store/cookieAccptContext";
import CookieBanner from "./components/cookieBanner/CookieBanner";
import Privacy from "./screens/privacy/Privacy";
import Terms from "./screens/terms/Terms";
import SignupValidationNeeded from "./screens/signupValidation/SignupValidationNeeded";
import SignupUserVerify from "./screens/signupUserVerification/SignupUserVerify";
import OrderDetailsBasic from "./screens/orderDetailsBasic/OrderDetailsBasic";
import ResetPassword from "./screens/resetPassword/ResetPassword";
import SellerHome from "./screens/demoTool/sellerHome/SellerHome";
import SellerProduct from "./screens/demoTool/sellerProduct/SellerProduct";
import ErrorPage from "./screens/errorPage/ErrorPage";
import WtisCombineBuy from "./screens/wtIsCombineBuy/WtisCombineBuy";
import MetaPixel from "./utils/MetaPixel";

function App() {
  // const { getUserDetailsFromStorage } = useContext(UserContext);
  // const { getCartItems } = useContext(CartContext);
  const location = useLocation();
  // console.log(location);
  const analyParamsCB = useAnlyParams();
  const { cookieAccpt } = useContext(CookieAccptContext);

  useEffect(() => {
    //console.log("App UseEffect Runs !!!! ");
    analyParamsCB({
      type: "view",
      pl: { pn: location.pathname, srch: location.search }, //right now srch style string is giving us the references like ?ref=red
    });
  }, [location]); /// Imp: the location dependency makes sure that useEffect is run on every page load

  //  console.log("The App is loading.. ");

  return (
    <>
      {process.env.NODE_ENV !== "development" && <MetaPixel />}
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/category/:category" element={<Category />} />
        <Route path="/allcategories" element={<AllCategories />} />
        <Route path="/cart" element={<Cart />} />
        <Route path="/checkout" element={<Checkout />} />
        <Route path="/orderconfirmation" element={<OrderConfirmation />} />
        <Route path="/product/:id" element={<ProductDetails />} />
        <Route path="/search/:keyword" element={<SearchScreen />} />
        <Route path="/account" element={<AccountScreen />} />
        <Route path="/contact" element={<ContactUsScreen />} />
        <Route path="/seller" element={<SellerContactUsScreen />} />
        <Route path="/howitworks" element={<HowItWorks />} />
        <Route path="/invite/:id" element={<Invite />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/terms" element={<Terms />} />
        <Route path="/suvalidate" element={<SignupValidationNeeded />} />
        <Route path="/suverify" element={<SignupUserVerify />} />
        <Route path="/order/:id" element={<OrderDetailsBasic />} />
        <Route path="/resetpassword" element={<ResetPassword />} />
        <Route path="/demoTool/sellerHome/:id" element={<SellerHome />} />
        <Route path="/demoTool/product/:id" element={<SellerProduct />} />
        <Route path="/error" element={<ErrorPage />} />
        <Route path="/wtiscombinebuy" element={<WtisCombineBuy />} />
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
      <Footer />
      {!cookieAccpt && <CookieBanner />}
      <ToastContainer />
    </>
  );
}

export default App;
