import { useEffect, useState } from "react";

import BackButton from "../ui/BackButton";
import "./compactProductList.css";
import CompactProductBox from "./CompactProductBox";
function CompactProductList({ categoryName, prods }) {
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResizeWindow = () => setWidth(window.innerWidth);
    // subscribe to window resize event "onComponentDidMount"
    window.addEventListener("resize", handleResizeWindow);
    return () => {
      // unsubscribe "onComponentDestroy"
      window.removeEventListener("resize", handleResizeWindow);
    };
  }, []);

  return (
    <>
      <BackButton />
      <div className="compactProdListDeliveryNoteContainer">
        For Express Delivery choose <strong>Free 36 hours Delivery </strong>
        at Checkout
      </div>
      <div className="compactProdCatTitle">Women's Clothing</div>
      <div className="compactProdListProdsContainer">
        {prods.map((prod, idx) => (
          <CompactProductBox prod={prod} key={idx} width={(width - 10) / 2} />
        ))}
      </div>
    </>
  );
}
export default CompactProductList;
