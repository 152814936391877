import "./ordersList.css";

function OrdersList({ setShowOneOrderDetails, ordersList }) {
  return (
    <div className="orderHistoryContainer">
      <div className="ordersHistoryHeading">Orders History</div>

      {ordersList.length < 1 ? (
        <div className="orderOverviewBox"> No Orders Placed </div>
      ) : (
        ordersList.map((order, idx) => (
          <div
            className="orderOverviewBox"
            onClick={() => setShowOneOrderDetails(order)}
            key={idx}
          >
            <div className="orderIdRow">Order Id: {order._id}</div>
            <div>Total: £ {order.totalAmt}</div>
            <div>Qty: {order.totalQty}</div>
            <div>Date : {order.createdAt.slice(0, 10)}</div>
            <div className="itemsRow">
              Items : {order.items[0].name.slice(0, 30) + "... "}
            </div>
          </div>
        ))
      )}
    </div>
  );
}

export default OrdersList;
