import "./productDescriptionSection.css";
import HorizontalLine from "../ui/HorizontalLine";
import { useContext, useEffect, useRef, useState } from "react";
import { CartContext } from "../../store/cartContext";
import { toast } from "react-toastify";
import { useAnlyParams } from "../../hooks/useAnlyParams";
import { useLocation, useNavigate } from "react-router-dom";
import { FaLeaf } from "react-icons/fa";
import ModalBasic from "../modals/basicModal/ModalBasic";
import ProductSizes from "./sizeVariants/ProductSizes";
import ProductVariants from "./relatedVariants/ProductVariants";

function CombineBuyProductDesciptionSection({
  prod,
  executeScrollToWtIsComebuyin,
}) {
  const [qty, setQty] = useState(1);
  const { cart, setCart } = useContext(CartContext);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedSize, setSelectedSize] = useState();
  const [sizeVariantSelected, setsizeVariantSelected] = useState();

  const navigate = useNavigate();
  const location = useLocation();
  const analyParamsCB = useAnlyParams();

  const getTimeRemaining = (expTime, remaining) => {
    //console.log("expTime is ", Date.parse(expTime), "time now is ", Date.now());
    //var timeDiff = Math.abs(Date.parse(expTime) - Date.now());
    var timeDiff = Date.parse(expTime) - Date.now();
    var hh = Math.floor(timeDiff / 1000 / 60 / 60);
    //console.log("hours diff is ", hh);

    if (hh < 0) {
      //setIsCombineDeal(false);
      return "Combine Deal over";
    }
    hh = ("0" + hh).slice(-2);

    timeDiff -= hh * 1000 * 60 * 60;
    var mm = Math.floor(timeDiff / 1000 / 60);
    mm = ("0" + mm).slice(-2);

    timeDiff -= mm * 1000 * 60;
    var ss = Math.floor(timeDiff / 1000);
    ss = ("0" + ss).slice(-2);

    //console.log("Time Diff- " + hh + ":" + mm + ":" + ss);
    return (
      "Finishing in " +
      hh +
      " hrs and " +
      mm +
      " mins; " +
      remaining +
      " more needed "
    );
  };

  const getInfoString = (expTime, remaining) => {
    var timeDiff = Date.parse(expTime) - Date.now();
    var hh = Math.floor(timeDiff / 1000 / 60 / 60);

    if (hh < 0) {
      //setIsCombineDeal(false);
      return "Combine Deal Over";
    }
    hh = ("0" + hh).slice(-2);

    timeDiff -= hh * 1000 * 60 * 60;
    var mm = Math.floor(timeDiff / 1000 / 60);
    mm = ("0" + mm).slice(-2);

    timeDiff -= mm * 1000 * 60;
    var ss = Math.floor(timeDiff / 1000);
    ss = ("0" + ss).slice(-2);

    //console.log("Time Diff- " + hh + ":" + mm + ":" + ss);
    return (
      "Pay only £ " +
      Number(prod.cbdeals.cbPrice).toLocaleString() +
      " if " +
      prod.cbdeals.cbRemaining +
      " more order(s) are received within next " +
      hh +
      " hrs and " +
      mm +
      " mins; "
    );
  };

  const addToCartHandler = (type) => {
    //console.log("Add to cart called for ", prod.name, " with qty ", qty);
    //console.log("The cart currently is ", cart); // the output puzzles me as first time it shows null as expected but second time onwards it shows the updated value of cart instead of previous value; even though the logging code is run prior to udpate of cart state
    // may be above behaviour could be because of log taking time and update of value taking less time than that.
    // delete this console.log("Testing ..........");

    //1/2 check if the product exits in the array
    var prodExists = false;
    if (cart && cart.length > 0) {
      prodExists =
        //cart.map((item) => item._id).indexOf(prod._id) > -1 ? true : false;
        cart
          .filter(
            (item) =>
              item._id === prod._id && item.sizeSelected === selectedSize
          )
          .map((item) => item._id)
          .indexOf(prod._id) > -1
          ? true
          : false;
    }

    //console.log("Prod Exits in cart already ? ", prodExists);

    //2/2 if it exits then update the qty else add the item
    if (prodExists) {
      let isDifferentType = true; // this is flag to check if the type is same for the existing product
      // update the qty if the deal type is also same (combine or buyNow)
      setCart(
        cart.map((item) => {
          item._id === prod._id &&
            item.type === type &&
            item.sizeSelected === selectedSize &&
            (item.qty = Number(item.qty) + Number(qty)) &&
            (isDifferentType = false);
          return item;
        })
      );
      if (isDifferentType) {
        //insert the product
        setCart(
          cart
            ? [
                ...cart,
                {
                  ...prod,
                  qty,
                  type,
                  sizeSelected: sizeVariantSelected && sizeVariantSelected.size,
                },
              ]
            : [
                {
                  ...prod,
                  qty,
                  type,
                  sizeSelected: sizeVariantSelected && sizeVariantSelected.size,
                },
              ]
        );
      }
    } else {
      //insert the product
      setCart(
        cart
          ? [
              ...cart,
              {
                ...prod,
                qty,
                type,
                sizeSelected: sizeVariantSelected && sizeVariantSelected.size,
              },
            ]
          : [
              {
                ...prod,
                qty,
                type,
                sizeSelected: sizeVariantSelected && sizeVariantSelected.size,
              },
            ]
      );
    }
    // analyParamsCB({
    //   type: "click",
    //   pl: {
    //     pn: location.pathname,
    //     clk: "addToCartProductBox",
    //     field: prod.name,
    //   },
    // });

    toast.success("Added to Cart", {
      position: "bottom-center",
      autoClose: 1500,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
  };

  const modalChoiceHandler = (choice) => {
    if (choice === "Switch") {
      //console.log("Combine Buy is final");
      analyParamsCB({
        type: "click",
        pl: {
          pn: location.pathname,
          clk: "SwitchToCombineBuyProdDescPage",
          field: prod.name,
        },
      });
      addToCartHandler("combine");
      navigate("/cart");
    } else if (choice === "Cancel") {
      //console.log("Buy now is final");
      addToCartHandler("buyNow");
      navigate("/cart");
    }
    setIsModalVisible(false);
  };

  const addAndGotoCartPage = (btn) => {
    //console.log("Add the product and take to cart");
    analyParamsCB({
      type: "click",
      pl: {
        pn: location.pathname,
        clk: btn + "ProdDescPage",
        field: prod.name,
      },
    });

    // first check if the product has size options and size is selected or not
    if (prod.sizes && prod.sizes.length && !selectedSize) {
      alert("Please select a Size!");
      return;
    }

    if (btn === "Combine") {
      addToCartHandler("combine");
      navigate("/cart");
    } else {
      //console.log("Buy Now");
      setIsModalVisible(true);
    }
  };

  const myRef = useRef(null);

  const executeScroll = () => myRef.current.scrollIntoView();
  // run this function from an event handler or an effect to execute scroll

  useEffect(() => {
    //console.log("Size selected or changed", selectedSize);
    setsizeVariantSelected(
      prod.sizes && prod.sizes.filter((item) => item.size === selectedSize)[0]
    );
  }, [selectedSize]);

  return (
    prod.description && (
      <div className="prodDescContainer">
        {isModalVisible && (
          <ModalBasic
            setIsModalVisible={setIsModalVisible}
            modalChoiceHandler={modalChoiceHandler}
            modalPrimaryMsg={
              "Switch to Combine Buy and Save £" + prod.cbdeals.cbSave + "?"
            }
            modalSecondaryMsg={"Tip: Combine Buy is more Planet Friendly! "}
            modalPrimaryBtnTxt={"Switch"}
            modalSecondaryBtnTxt={"Cancel"}
          />
        )}
        <div className="prodNameHeader">{prod.name}</div>
        {
          <div className="prodDescRow">
            {prod.description.length > 200 ? (
              <>
                <span>{prod.description.slice(0, 200) + "... "}</span>
                <span
                  onClick={() => {
                    executeScroll();
                    analyParamsCB({
                      type: "click",
                      pl: {
                        pn: location.pathname,
                        clk: "moreLinkProdPage",
                        field: prod.name,
                      },
                    });
                  }}
                  style={{ color: "blue", cursor: "pointer" }}
                >
                  more
                </span>
              </>
            ) : (
              prod.description
            )}
          </div>
        }

        <HorizontalLine />
        <div className="prodDetailsOneRow">
          {prod.feature1Name &&
            (prod.feature1Name === "Size" ? (
              <>
                <div className="prodDetailslabel">{prod.feature1Name}:</div>
                {sizeVariantSelected
                  ? sizeVariantSelected.size
                  : prod.feature1Value}
              </>
            ) : (
              <>
                <div className="prodDetailslabel">{prod.feature1Name}:</div>
                {prod.feature1Value}
              </>
            ))}
        </div>
        {prod.feature2Name && (
          <>
            <div className="prodDetailsOneRow">
              <div className="prodDetailslabel">{prod.feature2Name}:</div>
              {prod.feature2Value}
            </div>
          </>
        )}

        <div className="prodDetailsOneRow">
          <div className="prodDetailslabel">Price:</div>
          <span style={{ textDecoration: "line-through", color: "grey" }}>
            <span style={{ color: "red" }}>
              £{" "}
              {sizeVariantSelected
                ? Number(sizeVariantSelected.origPrice).toLocaleString() + " "
                : Number(prod.origPrice).toLocaleString() + " "}
            </span>
          </span>
        </div>
        <div className="prodDetailsOneRow">
          <div className="prodDetailslabel">Combine Buy Price:</div>
          <strong style={{ color: "black" }}>
            £ {Number(prod.cbdeals.cbPrice).toLocaleString()}{" "}
          </strong>
          <span
            style={{
              backgroundColor: "#ffe17d",
              marginLeft: "5px",
              fontSize: "0.7rem",
              padding: "3px 2px",
            }}
          >
            Save £
            {sizeVariantSelected
              ? Number(
                  sizeVariantSelected.origPrice - prod.cbdeals.cbPrice
                ).toLocaleString() + " "
              : Number(prod.cbdeals.cbSave).toLocaleString()}
          </span>
          <span className="prodDetailsInfoIcon">i</span>
          <span className="prodDetailsTooltiptext">
            {getInfoString(prod.cbdeals.cbExpiryTime, prod.cbdeals.cbRemaining)}
          </span>
        </div>
        <div className="prodDetailsCombineBuyDescRow">
          {getTimeRemaining(
            prod.cbdeals.cbExpiryTime,
            prod.cbdeals.cbRemaining
          )}{" "}
        </div>

        <div className="prodDetailsOneRow">
          <div
            className="prodDetailslabel"
            id="savingsElementForScrollReference"
          >
            Buy Now Price:
          </div>
          <strong style={{ color: "grey" }}>
            £
            {sizeVariantSelected
              ? Number(sizeVariantSelected.offerPrice).toLocaleString() + " "
              : Number(prod.offerPrice).toLocaleString()}
          </strong>
        </div>
        <HorizontalLine />
        {prod.sizes && (
          <ProductSizes
            sizes={prod.sizes}
            setSelectedSize={setSelectedSize}
            selectedSize={selectedSize}
          />
        )}
        <ProductVariants variantId={prod.variantId} prodId={prod._id} />
        <div className="combineBuyProdDescAddToCartContainer">
          <div className="combineBuyQtyCartRow">
            <div
              className="qtyIncrDcrBtn"
              onClick={() => setQty((qty) => Math.max(qty - 1, 1))}
            >
              {" "}
              -{" "}
            </div>
            <input
              className="qtyInput"
              type="text"
              value={qty}
              onChange={(event) => {
                setQty(
                  event.target.value > 10
                    ? 10
                    : event.target.value === "0"
                    ? 1
                    : event.target.value
                );
              }}
              onBlur={(event) => {
                qty === "" && setQty(Number(1));
              }}
            />
            <div
              className="qtyIncrDcrBtn"
              onClick={() => setQty((qty) => Math.min(qty + 1, 10))}
            >
              {" "}
              +{" "}
            </div>
          </div>
          <div>
            <div
              className="comebineBuyProdDescCombineBuyBtn"
              onClick={() => addAndGotoCartPage("Combine")}
            >
              Combine Buy
            </div>
            <div className="combineBuyBtnSubText">
              <FaLeaf color={"green"} /> Planet Friendly
            </div>
          </div>
          <div className="combineBuyChoiceText">-OR-</div>
          <div
            className="comebineBuyProdDescBuyNowBtn"
            onClick={() => addAndGotoCartPage("BuyNow")}
          >
            Buy Now
          </div>
        </div>
        <div className="combineBuyDetailsContainer">
          <div>Comebuyin Buy Details:</div>
          <div className="combineBuyDetailswriteup">
            {prod.cbdeals.cbRemaining} more order(s) needed by{" "}
            {new Date(prod.cbdeals.cbExpiryTime).getHours()}:
            {new Date(prod.cbdeals.cbExpiryTime).getMinutes() < 10
              ? "0" + new Date(prod.cbdeals.cbExpiryTime).getMinutes()
              : new Date(prod.cbdeals.cbExpiryTime).getMinutes()}{" "}
            {new Date(prod.cbdeals.cbExpiryTime).getHours() > 12 ? "pm" : "am"}.
            You are not charged till the order is confirmed. Received{" "}
            {prod.cbdeals.cbReceived} so far
          </div>
          <div
            onClick={() => executeScrollToWtIsComebuyin()}
            className="combineBuyDetailWtIsCombineBuy"
          >
            What is Combine Buy?
          </div>
        </div>
        <div className="prodDescNoteContainer">
          <div className="prodDescNotesBox" ref={myRef}>
            <div>30 Days Money Back Gurantee & Free Returns</div>
            <div>No upfront Payment requried - Pay after confirmation</div>
            <div>Quick confirmation </div>
          </div>
        </div>
      </div>
    )
  );
}

export default CombineBuyProductDesciptionSection;
