import { useEffect } from "react";
import { useState } from "react";
import { useServerAPICalls } from "../../hooks/useServerAPICalls";
import ProductBox from "../productBox/ProductBox";
import "./productGrid.css";
import ModalBasic from "../modals/basicModal/ModalBasic";

function ProductGrid() {
  const [topProds, setTopProds] = useState([]);

  const serverApiCallback = useServerAPICalls();

  const getTopProducts = async () => {
    //console.log("Get Top Products called ");
    const data = await serverApiCallback("get", "products/top", {});
    //console.log(data.data);
    setTopProds(data.data);
  };

  useEffect(() => {
    getTopProducts();
  }, []);

  return (
    <div className="productGridContainer">
      <div className="productsGridMainHeading">Trending Products</div>
      <div className="productGrid">
        {topProds.length > 0 &&
          topProds.map((prod, idx) => <ProductBox prod={prod} key={idx} />)}
      </div>
    </div>
  );
}

export default ProductGrid;
