import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import CartContextProvider from "./store/cartContext";
import UserContextProvider from "./store/userContext";
import { BrowserRouter as Router } from "react-router-dom";
import SSDevKeyContextProvider from "./store/ssDevKeyContext";
import CookieAccptContextProvider, {
  CookieAccptContext,
} from "./store/cookieAccptContext";
import ShoesContextProvider from "./store/shoesContext";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <CartContextProvider>
    <UserContextProvider>
      <SSDevKeyContextProvider>
        <CookieAccptContextProvider>
          <ShoesContextProvider>
            <Router>
              <App />
            </Router>
          </ShoesContextProvider>
        </CookieAccptContextProvider>
      </SSDevKeyContextProvider>
    </UserContextProvider>
  </CartContextProvider>
);
