import { useEffect, useState } from "react";
import { IoArrowForwardSharp } from "react-icons/io5";
import { useLocation, useNavigate } from "react-router-dom";
import { useAnlyParams } from "../../hooks/useAnlyParams";
import { useServerAPICalls } from "../../hooks/useServerAPICalls";
import "./bestDeals.css";

function BestDeals() {
  const [dealProducts, setDealProducts] = useState([]);
  const serverAPICallback = useServerAPICalls();
  const location = useLocation();
  const navigate = useNavigate();
  const analyParamsCB = useAnlyParams();

  const getProducts = async () => {
    const data = await serverAPICallback("get", "products/deals");
    //console.log("The Deal Products are ", data.data);
    setDealProducts(data.data);
  };

  useEffect(() => {
    getProducts();
  }, []);

  return (
    <div className="bestDealsOuterContainer">
      <div className="bestDealHeading">
        Awesome <span style={{ color: "red" }}>Deals </span>- Save{" "}
        <span style={{ color: "red" }}>£300 </span>or more{" "}
        <IoArrowForwardSharp />
      </div>
      <div className="bestDealsInnerContainer">
        {dealProducts.length > 0 &&
          dealProducts.map((deal, idx) => (
            <span
              key={idx}
              onClick={() => {
                analyParamsCB({
                  type: "click",
                  pl: {
                    pn: location.pathname,
                    clk: "topDealsProd",
                    field: JSON.stringify(deal.name),
                  },
                });
                navigate("product/" + deal._id);
              }}
            >
              <div className="bestDealsCategoryBox">
                <div className="bestDealsDiscountAmt">
                  £ {parseInt(deal.save)} Off
                </div>
                <img
                  src={
                    process.env.NODE_ENV === "development"
                      ? "http://192.168.1.133:5566/uploads/prodImages/prod_" +
                        deal.productId +
                        "/" +
                        deal.productId +
                        "_1.jpg"
                      : "https://lotcheaper.com/uploads/prodImages/prod_" +
                        deal.productId +
                        "/" +
                        deal.productId +
                        "_1.jpg"
                  }
                  className="bestDealsProductImage"
                />
                <div className="bestDealsProductName">
                  {deal.name.length < 80
                    ? deal.name
                    : deal.name.slice(0, 78) + "..."}
                </div>
              </div>
            </span>
          ))}
      </div>
    </div>
  );
}
export default BestDeals;
