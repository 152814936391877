import { useContext } from "react";
import { useState } from "react";
import { useServerAPICalls } from "../../hooks/useServerAPICalls";
import { UserContext } from "../../store/userContext";
import ForgotPasswordLine from "./ForgotPasswordLine";
import "./loginBox.css";
import { useLocation, useNavigate } from "react-router-dom";
import { useAnlyParams } from "../../hooks/useAnlyParams";

function LoginBox({ switchLoginSignup, isNotAccountPage }) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showError, setShowError] = useState(false);

  const location = useLocation();
  const navigate = useNavigate();
  const analyParamsCB = useAnlyParams();

  const serverApiCallback = useServerAPICalls();
  const { setUser } = useContext(UserContext);

  const loginHandler = async () => {
    //console.log("Login called with ", email, " ", password);
    if (email === "") {
      window.alert("Please enter Email Address");
      return;
    }
    if (password === "") {
      window.alert("Please enter Password");
      return;
    }

    const data = await serverApiCallback("post", "user/login", {
      email,
      password,
    });
    if (data.isError) {
      setShowError(true);
      return;
    }
    //console.log("The login data response is ", data.data);
    setUser({ name: data.data.name, token: data.data.token });
    if (isNotAccountPage) {
      navigate("/account");
    }
  };

  return (
    <div className="loginContainer">
      <div className="loginHeader">Login</div>

      <div className="signupDetailsRow">
        <div className="signupRowLabel">Email</div>
        <input
          className="signupRowInput"
          type="email"
          onChange={(e) => setEmail(e.target.value)}
        />
      </div>
      <div className="signupDetailsRow">
        <div className="signupRowLabel">Password</div>
        <input
          className="signupRowInput"
          type="password"
          onChange={(e) => setPassword(e.target.value)}
        />
      </div>
      {showError && (
        <div className="loginErrorRow">
          {" "}
          Invalid credentials! Please try again{" "}
        </div>
      )}
      <div className="loginBtnRow">
        <div
          className="singupBtn"
          onClick={() => {
            analyParamsCB({
              type: "click",
              pl: {
                pn: location.pathname,
                clk: "LoginBtn",
                field: email,
              },
            });
            loginHandler();
          }}
        >
          Login
        </div>
      </div>
      <div className="loginInsteadRow">
        Don't have an account ?{" "}
        <div
          className="loginSignupLink"
          onClick={() => {
            analyParamsCB({
              type: "click",
              pl: {
                pn: location.pathname,
                clk: "switchToSignBoxAccountPage",
              },
            });
            switchLoginSignup((current) => !current);
          }}
        >
          Signup
        </div>
        <ForgotPasswordLine />
      </div>
    </div>
  );
}

export default LoginBox;
