import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import HorizontalLine from "../../components/ui/HorizontalLine";
import { useAnlyParams } from "../../hooks/useAnlyParams";
import { useServerAPICalls } from "../../hooks/useServerAPICalls";
import "./orderDetailsBasic.css";

function OrderDetailsBasic() {
  const [order, setOrder] = useState();
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();
  const serverApiCallback = useServerAPICalls();
  const analyParamsCB = useAnlyParams();

  const getOrderDetails = async () => {
    //console.log("Going to send order details");
    const data = await serverApiCallback("get", "order/" + params.id);
    console.log("The order data returned is ", data.data);
    if (data.isError) {
      console.log("Error: ", data.msg);
      return;
    }
    setOrder(data.data);
  };
  useEffect(() => {
    getOrderDetails();
  }, []);
  return (
    <div className="orderDetailsPageContainer">
      {order ? (
        <>
          <div className="orderDetailsHeading">Your Order Details:</div>
          <div className="orderTotAmtRow">
            Total Amount: £{Number(order.totalAmt).toLocaleString()}
          </div>
          <div className="orderdetailsItemsContainer">
            {order.items.map((item) => (
              <div style={{ "min-height": "90px" }}>
                <div className="itemNameRow">
                  <img
                    className="itemImageBox"
                    src={
                      "https://lotcheaper.com/uploads/prodImages/prod_" +
                      item.imgId +
                      "/" +
                      item.imgId +
                      "_1.jpg"
                    }
                  />
                  $
                  {item.name.length > 50
                    ? item.name.slice(0, 100) + "..."
                    : item.name}
                </div>
                <div className="itemPriceRow">
                  Price: £{item.offerPrice.toLocaleString()} &emsp; Qty: $
                  {item.qty}
                </div>
                <div className="itemPriceRow">
                  Total To Pay: £
                  {Number(item.offerPrice * item.qty).toLocaleString()}
                </div>
              </div>
            ))}
          </div>
          <HorizontalLine />
        </>
      ) : (
        <div className="orderDetailsError">
          Oops! No Info found! Please double check the link ...
        </div>
      )}

      <div className="orderDetailsLoginRow">
        <span
          className="orderDetailsLoginBtn"
          onClick={() => {
            analyParamsCB({
              type: "click",
              pl: {
                pn: location.pathname,
                clk: "ManageOrdersBtn",
              },
            });
            navigate("/account");
          }}
        >
          Manage Orders
        </span>
      </div>
    </div>
  );
}
export default OrderDetailsBasic;
