import "./errorPage.css";
import { BiErrorAlt } from "react-icons/bi";

function ErrorPage() {
  return (
    <div className="orderConfirmationPageContainer">
      <div className="errorPageHeading">Oops! Something has gone wrong!!</div>
      <div className="errorPageSubHeading">
        <BiErrorAlt size={50} color={"red"} /> Seems like we have hit a snag
        ....... Please try again later !
      </div>
    </div>
  );
}

export default ErrorPage;
