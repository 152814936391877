import { useEffect, useState } from "react";
import { createContext } from "react";

export const UserContext = createContext();

//Note : The way the cart context works is :
// 0) initalisation happens in the account page where the local storage value is read by calling initialisetUserDetailsFromStorage from its own useEffect
// i) it passes the user and setUser to components so that cart is manipulated directly by components
// ii) it has useEffect dependent on user STATE itself, which will update the localstorage value one every time user data is updated

function UserContextProvider({ children }) {
  const [user, setUser] = useState();

  //read user Details from storage for initialisation
  const initialisetUserDetailsFromStorage = async () => {
    const userDetails = localStorage.getItem("user");
    //console.log("The stored User Details are ", userDetails);
    if (userDetails) {
      setUser(JSON.parse(userDetails));
    }
  };

  // make changes to the user Data in the storage
  const updateUserInStorage = async () => {
    // console.log(
    //   "User context changed so updating the local storage as well . User is ",
    //   user
    // );
    //1/2 check if the user is set to 'delete' meaning that logout has happened
    if (user === "delete") {
      localStorage.removeItem("user");
    } else {
      //2/2 else set the token
      // store only token ; no other detail for privacy reason
      localStorage.setItem("user", JSON.stringify({ token: user.token }));
    }
  };

  // to make sure that the data is updated in the storage whenever the state is changed or for the first time initialisation
  useEffect(() => {
    // console.log("Going to update the User in the context from useEffect");
    // console.log("The value of user is ", user);

    if (!user) {
      //console.log(
      //  "Inside if of the useEffect of userContext.No update will be done of the local storage immedialtely, rather we will first check if it exists in the local storage already."
      //);
      // If nothing is set in the memory then initialise it from localStorage
      initialisetUserDetailsFromStorage();
    } else {
      //console.log("Inside else of the useEffect of userContext");
      updateUserInStorage();
    }
  }, [user]);

  const value = {
    user,
    setUser,
    initialisetUserDetailsFromStorage,
  };
  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
}

export default UserContextProvider;
