import { useEffect, useState } from "react";
import { IoChevronForward } from "react-icons/io5";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useAnlyParams } from "../../hooks/useAnlyParams";
import { useServerAPICalls } from "../../hooks/useServerAPICalls";
import "./categories.css";

function Categories({ dontShowAllCatBtn }) {
  const [categories, setCategories] = useState([]);
  const serverApiCallback = useServerAPICalls();
  const navigate = useNavigate();
  const location = useLocation();
  const analyParamsCB = useAnlyParams();

  const getCategoriesData = async () => {
    //console.log("Get Categories called ");
    const data = await serverApiCallback("get", "products/category", {});
    //console.log(data.data);
    setCategories(data.data);
  };

  useEffect(() => {
    getCategoriesData();
  }, []);

  return (
    <div className="categoriesContainer">
      <div className="categoriesMainHeading">
        {dontShowAllCatBtn ? "All Categories" : "Top Categories"}
      </div>
      {categories.length > 0 && (
        <div className="categoriesGrid">
          {(!dontShowAllCatBtn ? categories.slice(0, 6) : categories).map(
            (category, idx) => (
              <span
                className="noDecorationLink"
                key={idx}
                onClick={() => {
                  analyParamsCB({
                    type: "click",
                    pl: {
                      pn: location.pathname,
                      clk: "categoryBoxHome",
                      field: JSON.stringify(category),
                    },
                  });
                  navigate("/category/" + category);
                }}
              >
                <div className="categoryBox">
                  <div className="categoryTitle">{category}</div>
                  <img
                    src={
                      (process.env.NODE_ENV === "development"
                        ? "http://192.168.1.133:5566"
                        : "https://comebuyin.com") +
                      "/uploads/categoryImages/" +
                      category.replace(/\s/g, "").toLowerCase() +
                      ".webp"
                    }
                    alt={category}
                    className="categoryImage"
                  />
                </div>
              </span>
            )
          )}
        </div>
      )}
      {!dontShowAllCatBtn && (
        <div className="allCategoriesRow">
          <div className="allCategoriesBtn">
            <Link to={"/allcategories"} className="noDecorationLink">
              All Categories
              <IoChevronForward size={".9rem"} />
            </Link>
          </div>
        </div>
      )}
    </div>
  );
}

export default Categories;
