import { useContext } from "react";
import CartItem from "../../components/cart/CartItem";
import CartSummaryBox from "../../components/cart/CartSummaryBox";
import CheckoutButton from "../../components/cart/CheckoutButton";
import BackButton from "../../components/ui/BackButton";
import { CartContext } from "../../store/cartContext";
import "./cart.css";
import HorizontalLine from "../../components/ui/HorizontalLine";

import Payments from "../../assets/PaymentOptions.png";
import { useLocation, useNavigate } from "react-router-dom";
import { useAnlyParams } from "../../hooks/useAnlyParams";

function Cart() {
  const { cart, setCart } = useContext(CartContext);
  const navigate = useNavigate();
  const location = useLocation();
  const analyParamsCB = useAnlyParams();

  return (
    <div className="cartPageContainer">
      <BackButton />
      {!cart || cart.length === 0 ? (
        <div className="cartEmptyMsg">Oops...No Items in the Cart !</div>
      ) : (
        <>
          <div className="cartHeading">Your Cart</div>
          <div className="cartSummaryRow ">
            <CartSummaryBox cart={cart} />
            <div className="cartSummaryMsgContainer">
              <HorizontalLine />
              <div className="cartSummaryTextAndProceedBtnContainer">
                <div className="cartSummaryBoxMsgsTextContainer">
                  <div className="summaryBoxMsgLine">
                    You are not charged till Order is Confirmed
                  </div>
                  <div className="summaryBoxMsgLine">
                    Enjoy 30 Days Free Returns
                  </div>
                  <div className="summaryBoxMsgLine">
                    Authorize Payment using PayPal or Credit Card
                  </div>
                </div>
                <div className="cartSummaryProceedBtnContainer">
                  <div
                    className="cartSummaryMsgProceedBtn"
                    onClick={() => {
                      analyParamsCB({
                        type: "click",
                        pl: {
                          pn: location.pathname,
                          clk: "proceedBtnOnCart",
                        },
                      });
                      navigate("/checkout");
                    }}
                  >
                    Proceed
                  </div>
                  <img
                    src={Payments}
                    className="cartSummaryPaymentOptionsImg"
                  />
                </div>
              </div>
            </div>

            {/* <div className="cartNotPayNowNotice">
              You can cancel your order even after confirmation
            </div> */}
          </div>
          <div className="cartNotPayNowNotice">
            *Payment deducted after Items are dispatched
          </div>
          <div className="cartTableHeading">
            <div className="productHeading">Product</div>
            <div className="quantityHeading">Quantity</div>
            <div className="priceHeading">Price</div>
            <div className="totalHeading">Total</div>
          </div>
          <div className="cartBox">
            {cart.map((cartItem, idx) => (
              <CartItem item={cartItem} key={idx} />
            ))}
          </div>
          <CheckoutButton />
        </>
      )}
    </div>
  );
}

export default Cart;
