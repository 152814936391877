import { useContext } from "react";
import { useEffect, useState } from "react";
import AccountDetails from "../../components/account/AccountDetails";
import LoginBox from "../../components/account/LoginBox";
import SignupBox from "../../components/account/SignupBox";
import { useServerAPICalls } from "../../hooks/useServerAPICalls";
import { UserContext } from "../../store/userContext";
import "./accountScreen.css";

/*Note:
First check if there is token or not in the local storage 
2-> If it exits then pull the data by calling the authenticate API and set the userContext and whether or not authenticated
3-> If token in local storage  doesnt exist then show the login or signup box
*/

function AccountScreen() {
  const [tryLogin, setTryLogin] = useState(false);
  const [authenticated, setAuthenticated] = useState(false);
  const { user, getUserDetailsFromStorage, setUser } = useContext(UserContext);
  const serverApiCallback = useServerAPICalls();

  // useEffect(() => {
  //   getUserDetailsFromStorage();
  // }, []);

  const authenticateToken = async () => {
    const data = await serverApiCallback("post", "user/auth", {
      token: user || user.token,
    });

    //console.log("Authenticated Data : ", data.data);
    if (data.data === true) {
      setAuthenticated(true);
    }
    //setUser({ ...data.data, token: data.data.id }); dont reset the data as it will cause the loop
  };

  useEffect(() => {
    if (user && user !== "delete") {
      //console.log("The user details currently are ", user);
      authenticateToken();
    } else {
      setAuthenticated(false);
    }
  }, [user]);

  return (
    <div className="accountPageContainer">
      {authenticated ? (
        <AccountDetails />
      ) : tryLogin ? (
        <SignupBox switchLoginSignup={setTryLogin} />
      ) : (
        <LoginBox switchLoginSignup={setTryLogin} />
      )}
    </div>
  );
}

export default AccountScreen;
