import BuyerDemoFeaturedProducts from "../../../demoTool/sellerHome/BuyerDemoFeaturedProducts";
import SellerProductBox from "../../../demoTool/sellerHome/SellerProductBox";
import "./sellerHome.css";

import { useParams } from "react-router-dom";

export const prodData = [
  {
    sellerId: "1132",
    _id: "1",
    name: "BEST SELLING PRODUCT NAME HERE",
    feature1Name: "Size",
    feature1Value: "XL",
    feature2Name: "color",
    feature2Value: "Black",
    origPrice: "100",
    offerPrice: "95",
    description:
      "lorem ipsum and some text over here and there for the description of the product . This might actually be empty so we have to make sure that this works for all cases",
  },
  {
    sellerId: "1132",
    _id: "2",
    name: "GREAT PRODUCT NAME HERE",
    feature1Name: "Size",
    feature1Value: "XL",
    feature2Name: "color",
    feature2Value: "Black",
    origPrice: "50",
    offerPrice: "48.5",
    description:
      "lorem ipsum and some text over here and there for the description of the product . This might actually be empty so we have to make sure that this works for all cases",
  },
  {
    sellerId: "1132",
    _id: "2",
    name: "GREAT PRODUCT NAME HERE",
    feature1Name: "Size",
    feature1Value: "XL",
    feature2Name: "color",
    feature2Value: "Black",
    origPrice: "50",
    offerPrice: "48.5",
  },
  {
    sellerId: 1,
    _id: "2",
    name: "GREAT PRODUCT NAME HERE",
    feature1Name: "Size",
    feature1Value: "XL",
    feature2Name: "color",
    feature2Value: "Black",
    origPrice: "35",
    offerPrice: "32.5",
  },
  {
    sellerId: 1,
    _id: "2",
    name: "AMAZING PRODUCT NAME HERE",
    feature1Name: "Size",
    feature1Value: "M",
    feature2Name: "color",
    feature2Value: "Green",
    origPrice: "80",
    offerPrice: "72.5",
  },
  {
    sellerId: "1132",
    _id: "100",
    name: "VERY GREAT PRODUCT NAME HERE",
    feature1Name: "Size",
    feature1Value: "XL",
    feature2Name: "color",
    feature2Value: "Black",
    origPrice: "50",
    offerPrice: "48.5",
    description:
      "lorem ipsum and some text over here and there for the description of the product . This might actually be empty so we have to make sure that this works for all cases",
  },
  {
    sellerId: "1132",
    _id: "100",
    name: "VERY GREAT PRODUCT NAME HERE",
    feature1Name: "Size",
    feature1Value: "XL",
    feature2Name: "color",
    feature2Value: "Black",
    origPrice: "50",
    offerPrice: "48.5",
    description:
      "lorem ipsum and some text over here and there for the description of the product . This might actually be empty so we have to make sure that this works for all cases",
  },
  {
    sellerId: "1132",
    _id: "120",
    name: "NEW EXCITING PRODUCT NAME HERE",
    feature1Name: "Size",
    feature1Value: "XL",
    feature2Name: "color",
    feature2Value: "Black",
    origPrice: "50",
    offerPrice: "48.5",
    description:
      "lorem ipsum and some text over here and there for the description of the product . This might actually be empty so we have to make sure that this works for all cases",
  },
  {
    sellerId: "1132",
    _id: "140",
    name: "VERY GREAT PRODUCT NAME HERE",
    feature1Name: "Size",
    feature1Value: "XL",
    feature2Name: "color",
    feature2Value: "Black",
    origPrice: "50",
    offerPrice: "48.5",
    description:
      "lorem ipsum and some text over here and there for the description of the product . This might actually be empty so we have to make sure that this works for all cases",
  },
  {
    sellerId: "1132",
    _id: "109",
    name: "VERY GREAT PRODUCT NAME HERE",
    feature1Name: "Size",
    feature1Value: "XL",
    feature2Name: "color",
    feature2Value: "Black",
    origPrice: "50",
    offerPrice: "48.5",
    description:
      "lorem ipsum and some text over here and there for the description of the product . This might actually be empty so we have to make sure that this works for all cases",
  },
  {
    sellerId: "1132",
    _id: "123",
    name: "NEW EXCITING PRODUCT NAME HERE",
    feature1Name: "Size",
    feature1Value: "XL",
    feature2Name: "color",
    feature2Value: "Black",
    origPrice: "50",
    offerPrice: "48.5",
    description:
      "lorem ipsum and some text over here and there for the description of the product . This might actually be empty so we have to make sure that this works for all cases",
  },
  {
    sellerId: "1132",
    _id: "123",
    name: "NEW EXCITING PRODUCT NAME HERE",
    feature1Name: "Size",
    feature1Value: "XL",
    feature2Name: "color",
    feature2Value: "Black",
    origPrice: "50",
    offerPrice: "48.5",
    description:
      "lorem ipsum and some text over here and there for the description of the product . This might actually be empty so we have to make sure that this works for all cases",
  },
  {
    sellerId: "1132",
    _id: "123",
    name: "NEW EXCITING PRODUCT NAME HERE",
    feature1Name: "Size",
    feature1Value: "XL",
    feature2Name: "color",
    feature2Value: "Black",
    origPrice: "50",
    offerPrice: "48.5",
    description:
      "lorem ipsum and some text over here and there for the description of the product . This might actually be empty so we have to make sure that this works for all cases",
  },
  {
    sellerId: "1132",
    _id: "123",
    name: "NEW EXCITING PRODUCT NAME HERE",
    feature1Name: "Size",
    feature1Value: "XL",
    feature2Name: "color",
    feature2Value: "Black",
    origPrice: "50",
    offerPrice: "48.5",
    description:
      "lorem ipsum and some text over here and there for the description of the product . This might actually be empty so we have to make sure that this works for all cases",
  },
  {
    sellerId: "1132",
    _id: "123",
    name: "NEW EXCITING PRODUCT NAME HERE",
    feature1Name: "Size",
    feature1Value: "XL",
    feature2Name: "color",
    feature2Value: "Black",
    origPrice: "50",
    offerPrice: "48.5",
    description:
      "lorem ipsum and some text over here and there for the description of the product . This might actually be empty so we have to make sure that this works for all cases",
  },
  {
    sellerId: "1132",
    _id: "123",
    name: "NEW EXCITING PRODUCT NAME HERE",
    feature1Name: "Size",
    feature1Value: "XL",
    feature2Name: "color",
    feature2Value: "Black",
    origPrice: "50",
    offerPrice: "48.5",
    description:
      "lorem ipsum and some text over here and there for the description of the product . This might actually be empty so we have to make sure that this works for all cases",
  },
  {
    sellerId: "1132",
    _id: "123",
    name: "NEW EXCITING PRODUCT NAME HERE",
    feature1Name: "Size",
    feature1Value: "XL",
    feature2Name: "color",
    feature2Value: "Black",
    origPrice: "50",
    offerPrice: "48.5",
    description:
      "lorem ipsum and some text over here and there for the description of the product . This might actually be empty so we have to make sure that this works for all cases",
  },
];
function SellerHome() {
  const params = useParams();
  const sellerId = params.id;

  return (
    <div>
      <div className="sellerHomeFirstNotice">
        This is a Dummy Products Listing Page
      </div>
      <div className="sellerHomeBannerImageContainer">
        INSERT BANNER IMAGE HERE
      </div>
      <BuyerDemoFeaturedProducts sellerId={params.id} />
      <div className="resultsContainer">
        <div className="productGrid">
          {prodData.length > 0 &&
            // below we are checking if category is Shoes & if so then we are showing filter and sorting randomly
            prodData
              .filter((p) => p.sellerId === params.id)
              .map((prod, idx) => <SellerProductBox prod={prod} key={idx} />)}
        </div>
      </div>
    </div>
  );
}
export default SellerHome;
