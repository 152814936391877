import "./productBox.css";
import { IoRemoveCircleOutline, IoAddCircleOutline } from "react-icons/io5";
import { useEffect, useState } from "react";
import { useContext } from "react";
import { CartContext } from "../../store/cartContext";
import { toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import { useAnlyParams } from "../../hooks/useAnlyParams";
import ModalBasic from "../modals/basicModal/ModalBasic";

function ProductBox({ prod }) {
  const [qty, setQty] = useState(1);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const { cart, setCart } = useContext(CartContext);
  const [isCombineDeal, setIsCombineDeal] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const analyParamsCB = useAnlyParams();

  const getTimeRemaining = (expTime, remaining) => {
    //console.log("expTime is ", Date.parse(expTime), "time now is ", Date.now());
    //var timeDiff = Math.abs(Date.parse(expTime) - Date.now());
    var timeDiff = Date.parse(expTime) - Date.now();
    var hh = Math.floor(timeDiff / 1000 / 60 / 60);
    //console.log("hours diff is ", hh);

    if (hh < 0) {
      //setIsCombineDeal(false);
      return "Combine Deal over";
    }
    hh = ("0" + hh).slice(-2);

    timeDiff -= hh * 1000 * 60 * 60;
    var mm = Math.floor(timeDiff / 1000 / 60);
    mm = ("0" + mm).slice(-2);

    timeDiff -= mm * 1000 * 60;
    var ss = Math.floor(timeDiff / 1000);
    ss = ("0" + ss).slice(-2);

    //console.log("Time Diff- " + hh + ":" + mm + ":" + ss);
    return (
      "Finishing in " +
      hh +
      " hrs and " +
      mm +
      " mins; " +
      remaining +
      " more to go "
    );
  };

  const getInfoString = (expTime, remaining) => {
    var timeDiff = Date.parse(expTime) - Date.now();
    var hh = Math.floor(timeDiff / 1000 / 60 / 60);

    if (hh < 0) {
      //setIsCombineDeal(false);
      return "Combine Deal Over";
    }
    hh = ("0" + hh).slice(-2);

    timeDiff -= hh * 1000 * 60 * 60;
    var mm = Math.floor(timeDiff / 1000 / 60);
    mm = ("0" + mm).slice(-2);

    timeDiff -= mm * 1000 * 60;
    var ss = Math.floor(timeDiff / 1000);
    ss = ("0" + ss).slice(-2);

    //console.log("Time Diff- " + hh + ":" + mm + ":" + ss);
    return (
      "Pay only £ " +
      Number(prod.cbdeals.cbPrice).toLocaleString() +
      " if " +
      prod.cbdeals.cbRemaining +
      " more order(s) are received within next " +
      hh +
      " hrs and " +
      mm +
      " mins; "
    );
  };

  const addToCartHandler = (type) => {
    //console.log("Add to cart called for ", prod.name, " with qty ", qty);
    //console.log("The cart currently is ", cart); // the output puzzles me as first time it shows null as expected but second time onwards it shows the updated value of cart instead of previous value; even though the logging code is run prior to udpate of cart state
    // may be above behaviour could be because of log taking time and update of value taking less time than that.
    // delete this console.log("Testing ..........");

    //1/2 check if the product exits in the array
    var prodExists = false;
    if (cart && cart.length > 0) {
      prodExists =
        cart.map((item) => item._id).indexOf(prod._id) > -1 ? true : false;
    }

    //console.log("Prod Exits in cart already ? ", prodExists);

    //2/2 if it exits then update the qty else add the item
    if (prodExists) {
      let isDifferentType = true; // this is flag to check if the type is same for the existing product
      // update the qty if the deal type is also same (combine or buyNow)
      setCart(
        cart.map((item) => {
          item._id === prod._id &&
            item.type === type &&
            (item.qty = Number(item.qty) + Number(qty)) &&
            (isDifferentType = false);
          return item;
        })
      );
      if (isDifferentType) {
        //insert the product
        setCart(
          cart ? [...cart, { ...prod, qty, type }] : [{ ...prod, qty, type }]
        );
      }
    } else {
      //insert the product
      setCart(
        cart ? [...cart, { ...prod, qty, type }] : [{ ...prod, qty, type }]
      );
    }
    // analyParamsCB({
    //   type: "click",
    //   pl: {
    //     pn: location.pathname,
    //     clk: "addToCartProductBox",
    //     field: prod.name,
    //   },
    // });

    toast.success("Added to Cart", {
      position: "bottom-center",
      autoClose: 1500,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
  };

  const modalChoiceHandler = (choice) => {
    if (choice === "Switch") {
      /* TODO: Add the Combine Buy Action for product  */
      //console.log("Combine Buy is final");
      analyParamsCB({
        type: "click",
        pl: {
          pn: location.pathname,
          clk: "switchChoiceToCombineBuyProductBox",
          field: prod.name,
        },
      });
      addToCartHandler("combine");
      navigate("/cart");
    } else if (choice === "Cancel") {
      // console.log("Buy now is final");
      addToCartHandler("buyNow");
      navigate("/cart");
    }
    setIsModalVisible(false);
  };
  const addAndGotoCartPage = (btn) => {
    //console.log("Add the product and take to cart");
    analyParamsCB({
      type: "click",
      pl: {
        pn: location.pathname,
        clk: btn + "ProductBox",
        field: prod.name,
      },
    });
    if (prod.sizes && prod.sizes.length > 0) {
      navigate("/product/" + prod._id);
    } else if (btn === "Combine") {
      /* TODO: Add the Combine Buy Action for product  */
      addToCartHandler("combine");
      navigate("/cart");
    } else {
      //console.log("Buy Now");
      if (isCombineDeal) {
        setIsModalVisible(true);
      } else {
        addToCartHandler("buyNow");
        navigate("/cart");
      }
    }
  };

  useEffect(() => {
    //console.log("prod data is ", prod);

    if (prod && prod.cbdeals) {
      var timeDiff = Date.parse(prod.cbdeals.cbExpiryTime) - Date.now();
      var hh = Math.floor(timeDiff / 1000 / 60 / 60);
      //console.log("hours diff is ", hh);

      if (hh > 0) {
        setIsCombineDeal(true);
        //return "Combine Deal over";
      }
    }
  }, []);
  return (
    <div className="productBox">
      {isModalVisible && (
        <ModalBasic
          setIsModalVisible={setIsModalVisible}
          modalChoiceHandler={modalChoiceHandler}
          modalPrimaryMsg={
            "Switch to Combine Buy and Save £" + prod.cbdeals.cbSave + "?"
          }
          modalSecondaryMsg={"Tip: Combine Buy is more Planet Friendly! "}
          modalPrimaryBtnTxt={"Switch"}
          modalSecondaryBtnTxt={"Cancel"}
        />
      )}
      <span
        className="productImageLinkNoDecoration"
        onClick={() => {
          analyParamsCB({
            type: "click",
            pl: {
              pn: location.pathname,
              clk: "imgProdBox",
              field: JSON.stringify(prod.name),
            },
          });
          navigate("/product/" + prod._id);
        }}
      >
        <img
          className="topProductImg"
          src={
            (process.env.NODE_ENV === "development"
              ? "http://192.168.1.133:5566"
              : "https://comebuyin.com") +
            "/uploads/prodImages/prod_" +
            prod.productId +
            "/" +
            prod.productId +
            "_1.jpg"
          }
          alt={
            "http://192.168.1.133:5566/uploads/prodImages/prod_" +
            prod.productId +
            "/" +
            prod.productId +
            "_1.jpg"
          }
        />
      </span>
      <span
        className="prodNameLink"
        onClick={() => {
          analyParamsCB({
            type: "click",
            pl: {
              pn: location.pathname,
              clk: "prodTitleHome",
              field: prod.name,
            },
          });
          navigate("/product/" + prod._id);
        }}
      >
        <div className="">
          {prod.name.length > 50 ? prod.name.slice(0, 60) + "..." : prod.name}
        </div>
      </span>
      {!(prod.sizes && prod.sizes.length > 0) && (
        <div className="prodSize">
          {prod.feature1Name} : {prod.feature1Value}
        </div>
      )}
      {prod.feature2Name && (
        <div className="prodSize">
          {prod.feature2Name} : {prod.feature2Value}
        </div>
      )}
      <div className="prodPriceRow">
        <div className="origPrice">
          £ {Number(prod.origPrice).toLocaleString()}
        </div>
        {/* <div className="offerPrice">
          £ {Number(prod.offerPrice).toLocaleString()}
        </div> */}
      </div>
      {isCombineDeal && prod.cbdeals && (
        <>
          <div className="prodBoxCombineBuyDetailRow">
            Combine Buy Price: £ {Number(prod.cbdeals.cbPrice).toLocaleString()}{" "}
            <span className="prodBoxCombineSave">
              {" "}
              Save £{Number(prod.cbdeals.cbSave).toLocaleString()}
            </span>
            <span
              className="prodBoxInfoIcon"
              onClick={() => navigate("/wtiscombinebuy")}
            >
              i
            </span>
            <span className="prodBoxTooltiptext">
              {getInfoString(
                prod.cbdeals.cbExpiryTime,
                prod.cbdeals.cbRemaining
              )}
            </span>
          </div>

          <div className="prodBoxCombineBuyDescRow">
            {getTimeRemaining(
              prod.cbdeals.cbExpiryTime,
              prod.cbdeals.cbRemaining
            )}{" "}
          </div>
        </>
      )}
      <div className="prodBoxCombineBuyDetailRow">
        Buy Now Price: £ {Number(prod.offerPrice).toLocaleString()}
      </div>
      {/* <div className="prodSaving">
        £{(Number(prod.origPrice) - Number(prod.offerPrice)).toLocaleString()}{" "}
        Off
      </div> */}
      <div className="prodCartRow">
        <IoRemoveCircleOutline
          size={"20px"}
          onClick={() => setQty((qty) => Math.max(qty - 1, 1))}
        />
        <input
          className="qtyInput"
          type="text"
          value={qty}
          onChange={(event) => {
            setQty(
              event.target.value > 10
                ? 10
                : event.target.value === "0"
                ? 1
                : event.target.value
            );
          }}
          onBlur={(event) => {
            qty === "" && setQty(Number(1));
          }}
        />
        <IoAddCircleOutline
          size={"20px"}
          onClick={() => setQty((qty) => Math.min(qty + 1, 10))}
        />
        {isCombineDeal && (
          <>
            <div
              className="prodBoxCombineBuyButton"
              onClick={() => addAndGotoCartPage("Combine")}
            >
              Combine Buy
            </div>
            <div className="prodBoxChoiceText">-OR-</div>
          </>
        )}
        <div
          className="prodBoxAddToCartButton"
          onClick={() => addAndGotoCartPage("BuyNow")}
        >
          Buy Now
        </div>
      </div>
    </div>
  );
}

export default ProductBox;
