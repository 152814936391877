import { useState } from "react";
import { useServerAPICalls } from "../../../hooks/useServerAPICalls";
import "./passwordChangeTab.css";

function PasswordChangeTab() {
  const [password, setPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirm, setConfirm] = useState("");
  const [error, setError] = useState(false);

  const serverAPICallBack = useServerAPICalls();

  const changePasswordHandler = async () => {
    if (password === "") {
      alert("Please Enter Current Password");
      return;
    }
    if (newPassword === "") {
      alert("Please Enter New Password");
      return;
    }
    if (confirm === "") {
      alert("Please Confirm the new Password");
      return;
    }
    if (newPassword !== confirm) {
      alert("The new passwords dont match !! ");
      return;
    }
    if (password === newPassword) {
      alert("The new and current password cannot be same ");
      return;
    }
    const data = await serverAPICallBack("put", "user/password", {
      password,
      newPassword,
    });
    if (data.isError) {
      setError(true);
      return;
    }
    console.log("Passsword changed ? ", data.data);
    alert("Password Changed Successfully!");
    setError(false);
    setPassword("");
    setNewPassword("");
    setConfirm("");
  };
  return (
    <div className="passwordChangeContainer">
      <div className="passwordChangeHeading">Change Password</div>
      <div className="passwordChangeFormBox">
        <div className="changePasswordRow">
          <div className="changePasswordLabel">Current Password: </div>

          <input
            type="password"
            className="changePasswordInput"
            onChange={(e) => setPassword(e.target.value)}
            value={password}
          />
        </div>
        <div className="changePasswordRow">
          <div className="changePasswordLabel">New Password:</div>
          <input
            type="password"
            className="changePasswordInput"
            onChange={(e) => setNewPassword(e.target.value)}
            value={newPassword}
          />
        </div>
        <div className="changePasswordRow">
          <div className="changePasswordLabel">Confirm Password:</div>
          <input
            type="password"
            className="changePasswordInput"
            onChange={(e) => setConfirm(e.target.value)}
            value={confirm}
          />
        </div>
        {error && (
          <div className="changePasswordErrorRow">
            Error: Password Entered is not correct! Please try again!
          </div>
        )}
        <div
          className="changePasswordBtn"
          onClick={() => changePasswordHandler()}
        >
          Change
        </div>
      </div>
    </div>
  );
}

export default PasswordChangeTab;
