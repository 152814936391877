import { useContext, useState } from "react";
import { IoHomeOutline } from "react-icons/io5";
import { useLocation, useNavigate } from "react-router-dom";

import CheckoutSummaryBox from "../../components/checkout/CheckoutSummaryBox";
import BackButton from "../../components/ui/BackButton";
import { CartContext } from "../../store/cartContext";
import "./checkout.css";

import ReCAPTCHA from "react-google-recaptcha";
import { useServerAPICalls } from "../../hooks/useServerAPICalls";
import { useAnlyParams } from "../../hooks/useAnlyParams";
import HorizontalLine from "../../components/ui/HorizontalLine";

import ClipLoader from "react-spinners/ClipLoader";
//import GridLoader from "react-spinners/GridLoader";

function Checkout() {
  const [name, setName] = useState("");
  const [address1, setAddress1] = useState("");
  const [address2, setAddress2] = useState("");
  const [city, setCity] = useState("");
  const [postCode, setPostCode] = useState("");
  const [email, setEmail] = useState("");
  const [captcha, setCaptcha] = useState(null);
  const [isErrorSubmission, setIsErrorSubmission] = useState(false);
  //const [termsChecked, setTermsChecked] = useState(false);
  //const [termsCheckError, setTermsCheckError] = useState(false);
  const [expressDeliveryCheck, setExpressDeliveryCheck] = useState(false);
  const [signupCheck, setSignupCheck] = useState(false);

  let [loading, setLoading] = useState(false);
  let [color, setColor] = useState("black");

  const { cart, setCart } = useContext(CartContext);
  const navigate = useNavigate();
  const serverApiCallback = useServerAPICalls();
  const location = useLocation();
  const analyParamsCB = useAnlyParams();

  function onCaptchaVerification(value) {
    //console.log("Captcha value:", value);
    setCaptcha(value);
  }

  const sendOrderDetails = async () => {
    //console.log("Going to send order details");
    const data = await serverApiCallback("post", "order", {
      captcha,
      items: cart.map((item) => ({
        _id: item._id,
        qty: item.qty,
        type: item.type,
        sizeSelected: item.sizeSelected,
      })),
      shipping: { name, address1, address2, city, postCode, email },
      didSignUp: signupCheck,
      choseExpressDelivery: expressDeliveryCheck,
    });
    //console.log("The order data returned is ", data.data);
    if (data.isError) {
      console.log("Error: ", data.msg);
      setIsErrorSubmission(true);
      return;
    }
    setCart([]);
    //navigate("/orderConfirmation");
    navigate("/error");
  };

  const placeOrderHandler = async () => {
    // console.log("Place Order !!! ");
    // console.log(
    //   "The values are : ",
    //   name,
    //   address1,
    //   address2,
    //   city,
    //   postCode,
    //   email
    // );

    if (name === "") {
      alert("Please Enter Name");
      return;
    }
    if (address1 === "") {
      alert("Please Enter Address1");
      return;
    }
    if (city === "") {
      alert("Please Enter City/Town");
      return;
    }
    if (postCode === "") {
      alert("Please Enter PostCode/ZipCode");
      return;
    }
    if (email === "") {
      alert("Please Enter Email");
      return;
    }
    if (!captcha) {
      alert("Please verify the captcha");
      return;
    }
    // if (!termsChecked) {
    //   setTermsCheckError(true);
    //   return;
    // }
    setLoading(!loading);
    setTimeout(() => {
      setLoading(false);
      sendOrderDetails();
    }, 10000);
  };

  return (
    <div className="checkoutPageContainer">
      <BackButton />
      {!cart || cart.length === 0 ? (
        <div className="cartEmptyMsg">
          Oops...No Items in the Cart to Checkout !
        </div>
      ) : (
        <>
          <CheckoutSummaryBox cart={cart} clickHandler={placeOrderHandler} />
          <div className="addressBox">
            <div className="addressHeader">
              <IoHomeOutline size={"15px"} />
              <div> Delivery & Payment </div>
            </div>
            <div className="checkoutAddressPaymentParentContainer">
              <div className="checkoutAddressBodySection">
                <div className="addressBodyMain">
                  <div className="addressInputContainer">
                    <div className="inputLabel">
                      Name<span className="redAsterisk">*</span>
                    </div>
                    <input
                      className="inputBox"
                      onChange={(event) => {
                        setName(event.target.value);
                      }}
                    />
                  </div>
                  <div className="addressInputContainer">
                    <div className="inputLabel">
                      Address Line 1<span className="redAsterisk">*</span>
                    </div>
                    <input
                      className="inputBox"
                      onChange={(event) => {
                        setAddress1(event.target.value);
                      }}
                    />
                  </div>
                  <div className="addressInputContainer">
                    <div className="inputLabel">Address Line 2</div>
                    <input
                      className="inputBox"
                      onChange={(event) => {
                        setAddress2(event.target.value);
                      }}
                    />
                  </div>
                  <div className="addressInputContainer">
                    <div className="inputLabel">
                      City/Town<span className="redAsterisk">*</span>
                    </div>
                    <input
                      className="inputBox"
                      onChange={(event) => {
                        setCity(event.target.value);
                      }}
                    />
                  </div>
                  <div className="addressInputContainer">
                    <div className="inputLabel">
                      Post Code / Zip Code<span className="redAsterisk">*</span>
                    </div>
                    <input
                      className="inputBox"
                      onChange={(event) => {
                        setPostCode(event.target.value);
                      }}
                    />
                  </div>
                  <div className="addressInputContainer">
                    <div className="inputLabel">
                      Email<span className="redAsterisk">*</span>
                    </div>
                    <input
                      className="inputBox"
                      type="email"
                      onChange={(event) => {
                        setEmail(event.target.value);
                      }}
                    />
                  </div>
                </div>

                {isErrorSubmission && (
                  <div className="cartErrorSubmission">
                    Error! Please try again
                  </div>
                )}

                <div className="checkoutCaptchaBox">
                  <ReCAPTCHA
                    //sitekey="6LdvwIckAAAAACvnmmU2nv9SjTpjebRw2dzNT6Mb"
                    //: localhost -
                    //sitekey="6Ldueu0kAAAAALNNOmehsaJc-Ftfh1IoqhvvzTNZ"
                    // comebuyin:
                    //sitekey="6LdiF_snAAAAAAA3Xa9tGlL-auCc8CNTLI-17f9h"
                    sitekey={
                      process.env.NODE_ENV === "development"
                        ? "6Ldueu0kAAAAALNNOmehsaJc-Ftfh1IoqhvvzTNZ"
                        : "6LdiF_snAAAAAAA3Xa9tGlL-auCc8CNTLI-17f9h"
                    }
                    onChange={onCaptchaVerification}
                  />
                </div>
                <div className="termsCheckContainer">
                  <input
                    type="checkbox"
                    defaultChecked={expressDeliveryCheck}
                    onChange={() =>
                      setExpressDeliveryCheck(!expressDeliveryCheck)
                    }
                  />
                  <div style={{ marginTop: "3px" }}>
                    Free 36 Hours Express Delivery
                  </div>
                </div>
                <div className="termsCheckContainer">
                  <label>
                    <input
                      type="checkbox"
                      defaultChecked={signupCheck}
                      onChange={() => setSignupCheck(!signupCheck)}
                    />
                    Sign up & Create an Account (Earn 1000 Bonus Points)
                  </label>
                </div>
                {/* {termsCheckError && (
                  <div className="cartErrorSubmission">
                    Please read and accept Terms and Conditions to proceed.
                  </div>
                )} */}
                {/* <div
              className="placeOrderBtn"
              onClick={() => {
                analyParamsCB({
                  type: "click",
                  pl: {
                    pn: location.pathname,
                    clk: "placeOrderBtnBottom",
                  },
                });
                placeOrderHandler();
              }}
            >
              Place Order
            </div>
            <div className="disclaimerText">
              *Payment after Order Confirmation{" "}
            </div> */}
              </div>
              <div className="checkoutPaymentSectionContainer">
                <div className="checkoutPaymentHeader">PAYMENT</div>
                <div className="checkoutPaymentAuthoriseLine">
                  {" "}
                  Payment Methods
                </div>
                <div
                  className="checkoutPPbutton"
                  onClick={() => {
                    analyParamsCB({
                      type: "click",
                      pl: {
                        pn: location.pathname,
                        clk: "paypalBtnOrderBtn",
                      },
                    });
                    placeOrderHandler();
                  }}
                >
                  {" "}
                  Pay<span className="checkoutPPButtonPal">Pal</span>
                </div>
                <div
                  className="checkoutCardsbutton"
                  onClick={() => {
                    analyParamsCB({
                      type: "click",
                      pl: {
                        pn: location.pathname,
                        clk: "debitCreditOrderBtn",
                      },
                    });
                    placeOrderHandler();
                  }}
                >
                  Debit or Credit Card
                </div>
                <div
                  style={{
                    width: "100%",
                    marginTop: "40px",
                    fontSize: "0.7rem",
                    textAlign: "center",
                  }}
                >
                  By continuing you agree to{" "}
                  <span
                    onClick={() => {
                      analyParamsCB({
                        type: "click",
                        pl: {
                          pn: location.pathname,
                          clk: "termsLinkCheckoutPage",
                        },
                      });
                      navigate("/terms");
                    }}
                    className="termsLinkcheckout"
                  >
                    Terms & Conditions
                  </span>
                  <HorizontalLine />
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      <div
        className="checkoutPaymentLoaderContainer"
        style={loading ? { padding: "20px" } : { padding: "0px" }}
      >
        <ClipLoader
          color="#36d7b7"
          loading={loading}
          cssOverride={{}}
          aria-label="Loading Spinner"
          data-testid="loader"
          size={45}
        />
      </div>
      {/* <GridLoader
        color={color}
        loading={loading}
        cssOverride={{
          display: "block",
          margin: "0 auto",
          borderColor: "red",
        }}
        size={150}
        aria-label="Loading Spinner"
        data-testid="loader"
      /> */}
    </div>
  );
}

export default Checkout;
