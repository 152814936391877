import "./accountDetails.css";
import HorizontalLine from "../ui/HorizontalLine";
import { useEffect, useState } from "react";
import OrderHistoryTab from "./Order/OrderHistoryTab";
import PasswordChangeTab from "./password/PasswordChangeTab";
import EditProfileTab from "./profile/EditProfileTab";
import InviteTab from "./invite/InviteTab";
import LogoutBtn from "./logout/LogoutBtn";
import { useLocation } from "react-router-dom";

function AccountDetails() {
  const [selected, setSelected] = useState("orders");
  const location = useLocation();

  const clickSelectionHandler = (select) => {
    setSelected(select);
  };

  useEffect(() => {
    const query = new URLSearchParams(window.location.search);
    //console.log("The page refferred is ", query.get("pg"));
    if (query.get("pg") === "invite") {
      setSelected("invite");
    }
  }, [location.state]); // this we set as random value in the navigate in the link (footer)

  return (
    <div className="accountDetailsContainer">
      <div className="selectionsCol">
        <div
          className={
            selected === "orders"
              ? "selectionItem activeSelection"
              : "selectionItem"
          }
          onClick={() => {
            clickSelectionHandler("orders");
          }}
        >
          Orders
        </div>
        <HorizontalLine />
        <div
          className={
            selected === "password"
              ? "selectionItem activeSelection"
              : "selectionItem"
          }
          onClick={() => {
            clickSelectionHandler("password");
          }}
        >
          Change Password
        </div>
        <HorizontalLine />
        <div
          className={
            selected === "profile"
              ? "selectionItem activeSelection"
              : "selectionItem"
          }
          onClick={() => {
            clickSelectionHandler("profile");
          }}
        >
          Edit My Profile
        </div>
        <HorizontalLine />
        <div
          className={
            selected === "invite"
              ? "selectionItem activeSelection"
              : "selectionItem"
          }
          onClick={() => {
            clickSelectionHandler("invite");
          }}
        >
          Invite & Earn
        </div>
        <HorizontalLine />
        <div
          className={
            selected === "logout"
              ? "selectionItem activeSelection"
              : "selectionItem"
          }
          onClick={() => {
            clickSelectionHandler("logout");
          }}
        >
          Logout
        </div>
        <HorizontalLine />
      </div>
      <div className="outputCol">
        {(() => {
          switch (selected) {
            case "orders":
              return <OrderHistoryTab />;
            //break;
            case "password":
              return <PasswordChangeTab />;
            //break;
            case "profile":
              return <EditProfileTab />;
            //break;
            case "logout":
              return <LogoutBtn />;
            //break;
            case "invite":
              return <InviteTab />;
            //break;
          }
        })()}
      </div>
    </div>
  );
}

export default AccountDetails;
