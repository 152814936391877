import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import LoginBox from "../../components/account/LoginBox";
import SignupBox from "../../components/account/SignupBox";
import { useServerAPICalls } from "../../hooks/useServerAPICalls";
import "./signupUserVerify.css";

function SignupUserVerify() {
  const [tryLogin, setTryLogin] = useState(false);
  const [isVerified, setIsVerfied] = useState(false);
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const serverAPICallback = useServerAPICalls();

  const getValidationStatus = async () => {
    // lets just make sure that the query params are available which should be ; but just to be safe putting an if else statement
    if (query.get("_id")) {
      const data = await serverAPICallback("put", "user/verify", {
        _id: query.get("_id"),
        activationUrlRef: query.get("ref"),
      });
      if (!data.isError) {
        //console.log("The user is validated: ", data.data);
        setIsVerfied(true);
      }
    } else {
      console.log("Error: The user is not validated yet!");
    }
  };

  useEffect(() => {
    getValidationStatus();
  }, []);

  return (
    <div className="verifyEmailPageContainer">
      {isVerified ? (
        <>
          <div className="signupVerifiedTxtContainer">
            Email Verified. Please Login
          </div>
          {tryLogin ? (
            <SignupBox switchLoginSignup={setTryLogin} />
          ) : (
            <LoginBox switchLoginSignup={setTryLogin} isNotAccountPage={true} />
          )}
          <br></br>
        </>
      ) : (
        <div className="FailedSignupVerifiedTxtContainer">
          Oops! The link is not Right, Email not Verified, Please Try Again!
        </div>
      )}
    </div>
  );
}
export default SignupUserVerify;
