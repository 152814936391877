import "./signupValidationNeeded.css";
function SignupValidationNeeded() {
  return (
    <div className="signupValidationNeededPageContainer">
      <div className="signupValidationNeededHeader">
        Account Created. Please verify your email address.
      </div>
      <div className="signupValidationNeededParagraph">
        Thank you ! We have sent you an email with a validation link. Please
        click on the link to verify your email address.
        <br />
        In case you haven't received it in your inbox, please check your spam
        folder.
      </div>
    </div>
  );
}
export default SignupValidationNeeded;
