import { Link } from "react-router-dom";
import "./productVariantItem.css";
function ProductVariantItem({ prod }) {
  return (
    <div className="productVariantItemContainer">
      <Link to={"/product/" + prod._id}>
        <img
          className="productVariantItemImg"
          src={
            (process.env.NODE_ENV === "development"
              ? "http://192.168.1.133:5566"
              : "https://comebuyin.com") +
            "/uploads/prodImages/prod_" +
            prod.productId +
            "/" +
            prod.productId +
            "_1.jpg"
          }
        />
      </Link>
    </div>
  );
}
export default ProductVariantItem;
