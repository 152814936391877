import "./hero.css";
import LogoHero from "../Logos/LogoHero";
import { Link, Navigate, useLocation, useNavigate } from "react-router-dom";
import { useAnlyParams } from "../../hooks/useAnlyParams";
import Logo from "../../assets/logo.svg";
import Payments from "../../assets/PaymentOptions.png";

function Hero() {
  const location = useLocation();
  const analyParamsCB = useAnlyParams();
  const navigate = useNavigate();

  return (
    <>
      <div className="containerHero">
        {/* <LogoHero /> */}
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <img className="heroLogoImage" src={Logo} />
        </div>
        <span className="heroTextHeading">Reasons to Love Us</span>
        <div className="textHeroContainer">
          <span>
            Great Value | Brand New Products | Free 30 Days Return | Free
            Express Delivery
          </span>
          <div>
            <img
              src={Payments}
              className="cartSummaryPaymentOptionsImg"
              atl="Pay Securely"
            />
          </div>
        </div>
        {/* <Link
          to={"/howitworks"}
          className="heroNoDecorationLink"
          onClick={() => {
            analyParamsCB({
              type: "click",
              pl: {
                pn: location.pathname,
                clk: "howItWorkLinkHero",
                //field: JSON.stringify({ email }),
              },
            });
          }}
        >
          <div className="howItWorksHero">How it works ?</div>
        </Link> */}
        <div
          className="sellWithUsHero"
          onClick={() => {
            analyParamsCB({
              type: "click",
              pl: {
                pn: location.pathname,
                clk: "sellWithUsHeroLink",
              },
            });
            navigate("seller");
          }}
        >
          Sell with us for Free
        </div>
      </div>
    </>
  );
}

export default Hero;
