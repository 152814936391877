import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useAnlyParams } from "../../hooks/useAnlyParams";
import { useServerAPICalls } from "../../hooks/useServerAPICalls";
import "./emailInput.css";

function EmailInput({ setEmailProvided, setUserId, setResetCode }) {
  const [email, setEmail] = useState();
  const [confirm, setConfirm] = useState();
  const [error, setError] = useState();

  const location = useLocation();
  const analyParamsCB = useAnlyParams();

  const serverApiCallback = useServerAPICalls();

  const resetEmailCheck = async () => {
    if (!email) {
      setError("Error! Please Enter Email");
      return;
    }
    if (!confirm) {
      setError("Error! Please Confirm Email");
      return;
    }
    if (confirm !== email) {
      setError("Error!  Emails entered dont Match");
      return;
    }
    const data = await serverApiCallback("put", "user/resetcode", { email });
    //console.log("Email Reset Data returned is ", data.data);
    if (data.isError) {
      console.log("Error: ", data.msg);
      setError("Error!  Please Check Email try again");
      return;
    }
    setUserId(data.data.id);
    setResetCode(data.data.resetCode);
    setEmailProvided(true);
  };

  return (
    <div className="resetPasswordContainer">
      <div className="loginHeader">Reset Password</div>

      <div className="signupDetailsRow">
        <div className="signupRowLabel">Email</div>
        <input
          className="signupRowInput"
          type="email"
          onChange={(e) => setEmail(e.target.value)}
        />
      </div>
      <div className="signupDetailsRow">
        <div className="signupRowLabel">Confirm Email</div>
        <input
          className="signupRowInput"
          type="email"
          onChange={(e) => setConfirm(e.target.value)}
        />
      </div>
      {error && <div className="resetPassErrorRow"> {error}</div>}
      <div className="nextBtnRow">
        <div
          className="singupBtn"
          onClick={() => {
            analyParamsCB({
              type: "click",
              pl: {
                pn: location.pathname,
                clk: "nextBtnPwReset",
                field: email,
              },
            });
            resetEmailCheck();
          }}
        >
          Next
        </div>
      </div>
    </div>
  );
}
export default EmailInput;
