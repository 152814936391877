import axios from "axios";
import { useContext } from "react";
import { SSDevKeyContext } from "../store/ssDevKeyContext";
import { useServerAPICalls } from "./useServerAPICalls";

export const useAnlyParams = () => {
  const serverAPICallback = useServerAPICalls();
  const { ssDevKey, setSsDevKey, initialiseSsDevKeyfromStorage } =
    useContext(SSDevKeyContext);

  const analyParamsCallback = async (data) => {
    //console.log(data);

    //1/2 check if there is ssDevKey present in context otherwise get it from the server
    var ssDevKeyVal;
    //console.log("The key value in memory is ", ssDevKey);

    if (ssDevKey) {
      ssDevKeyVal = ssDevKey;
    } else {
      // 1.1 first try retrieve it from localStorage as sometimes when website is loaded for the first time the context is not ready and we need to use await method
      ssDevKeyVal = await initialiseSsDevKeyfromStorage();

      //1.2 if the value is still null then we get a new one
      if (!ssDevKeyVal) {
        const data = await serverAPICallback("get", "anlyparam/ssdevkey");
        //console.log("The ssKeyValue is " + data.data);
        ssDevKeyVal = data.data;
        setSsDevKey(data.data);
      }
    }
    data.pl = { ...data.pl, channel: "web", ssdevkey: ssDevKeyVal };

    // 2/2 Send the data
    serverAPICallback("post", "anlyparam", data);
  };
  return analyParamsCallback;
};
