import React, { useCallback, useState } from "react";

import { useBottomScrollListener } from "react-bottom-scroll-listener";
import { useLocation } from "react-router-dom";
import { useAnlyParams } from "../../hooks/useAnlyParams";

function BottomPage({ pageName }) {
  const [bottomHitAlready, setBottomHitAlready] = useState(false);
  const location = useLocation();
  const analyParamsCB = useAnlyParams();

  const handleOnDocumentBottom = useCallback(() => {
    //console.log(bottomHitAlready ? "true" : "false");
    if (!bottomHitAlready) {
      analyParamsCB({
        type: "scroll",
        pl: {
          pn: location.pathname,
          field: pageName,
        },
      });
      setBottomHitAlready(true);
    }
    //alert("Reached Bottom !");
  }, [bottomHitAlready]);

  // Below can also work but it doesnt memoise like with useCallBack
  //   const handleOnDocumentBottom = () => {
  //     console.log("I am at bottom! " + hitAlready ? "true" : "false");

  //     //   if (alertOnBottom) {
  //     const str = hitAlready ? "true value " : "false value";
  //     alert(str);
  //     setHitAlready(true);
  //     //  }
  //   };

  useBottomScrollListener(handleOnDocumentBottom);
  return <></>;
}
export default BottomPage;
