import { useState } from "react";
import { IoSearchSharp } from "react-icons/io5";
import { useLocation, useNavigate } from "react-router-dom";
import { useAnlyParams } from "../../hooks/useAnlyParams";
import "./searchProductBtn.css";

function SearchProductBtn() {
  const [keyword, setKeyword] = useState();
  const navigate = useNavigate();
  const location = useLocation();

  const analyParamsCB = useAnlyParams();

  const searchProductHandler = () => {
    //console.log("Search products with keyword ", keyword);
    if (keyword) {
      analyParamsCB({
        type: "search",
        pl: {
          pn: location.pathname,
          clk: "search",
          field: keyword,
        },
      });
      navigate("/search/" + keyword);
    } else {
      return;
    }
  };

  return (
    <div className="searchBtnRowContainer">
      <div className="searchRowTxt">Discover More</div>
      <input
        type="search"
        className="searchInputBttm"
        onChange={(event) => {
          setKeyword(event.target.value);
        }}
        onKeyDown={(event) => {
          if (event.key === "Enter") {
            searchProductHandler();
          }
        }}
        placeholder={"Search More Products"}
      />
      <div
        className="searchIconContainer"
        onClick={() => searchProductHandler()}
      >
        <IoSearchSharp size={"1.5rem"} color={"black"} />
      </div>
    </div>
  );
}
export default SearchProductBtn;
