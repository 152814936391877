import "./header.css";
import { IoSearchSharp, IoMenu, IoCartOutline } from "react-icons/io5";
import { useEffect, useState } from "react";
import { useContext } from "react";
import { CartContext } from "../../store/cartContext";
import { Link, useLocation, useNavigate } from "react-router-dom";

import LogoHeader from "../Logos/LogoHeader";
import { useAnlyParams } from "../../hooks/useAnlyParams";
import Logo from "../../assets/logo.svg";
import LogoBlack from "../../assets/logoBlack.png";

function Header() {
  const [showToggleLinks, setShowToggleLinks] = useState(false);
  const [keyword, setKeyword] = useState();
  const { cart } = useContext(CartContext);
  const navigate = useNavigate();
  const location = useLocation();
  const analyParamsCB = useAnlyParams();

  const handleScroll = () => {
    setShowToggleLinks(false);
  };
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  });

  const searchProductHandler = () => {
    //console.log("Search products with keyword ", keyword);
    if (keyword) {
      analyParamsCB({
        type: "search",
        pl: {
          pn: location.pathname,
          clk: "search",
          field: keyword,
        },
      });
      navigate("/search/" + keyword);
    } else {
      return;
    }
  };

  return (
    <div className="outerContainer">
      <div className="innerContainer">
        <div
          className="brandNameContainer"
          onClick={() => {
            analyParamsCB({
              type: "click",
              pl: {
                pn: location.pathname,
                clk: "brandHeader",
                //field: JSON.stringify({ email }),
              },
            });
            navigate("/");
          }}
        >
          {/*<img src={logo} className="brandLogo" alt="Sales Only" />*/}
          {/* <LogoHeader /> */}
          <div
            style={{
              border: "1px white solid",
              display: "flex",
              alignContent: "center",
              justifyContent: "center",
            }}
          >
            <img className="headerLogoImg" src={LogoBlack} />
          </div>
        </div>

        <div className="searchBar">
          <input
            type="search"
            className="searchInput"
            onChange={(event) => {
              setKeyword(event.target.value);
            }}
            onKeyDown={(event) => {
              if (event.key === "Enter") {
                searchProductHandler();
              }
            }}
          />
          <div
            className="searchIconContainer"
            onClick={() => searchProductHandler()}
          >
            <IoSearchSharp size={"1.5rem"} color={"black"} />
          </div>
        </div>

        <div className="headerEnd">
          <div className="linksContainer">
            <div className="links">
              <Link
                to="/"
                className="noDecorationLink"
                onClick={() => {
                  analyParamsCB({
                    type: "click",
                    pl: {
                      pn: location.pathname,
                      clk: "homeLinkHeader",
                      //field: JSON.stringify({ email }),
                    },
                  });
                }}
              >
                Home
              </Link>
            </div>
            <Link
              to="/cart"
              className="noDecorationLink"
              onClick={() => {
                analyParamsCB({
                  type: "click",
                  pl: {
                    pn: location.pathname,
                    clk: "cartLinkHeader",
                    //field: JSON.stringify({ email }),
                  },
                });
              }}
            >
              <div className="links">Checkout</div>
            </Link>
            <Link
              to="/account"
              className="noDecorationLink"
              onClick={() => {
                analyParamsCB({
                  type: "click",
                  pl: {
                    pn: location.pathname,
                    clk: "accountLinkHeader",
                    //field: JSON.stringify({ email }),
                  },
                });
              }}
            >
              <div className="links">Account</div>
            </Link>
          </div>
          <div
            className="burgerMenu"
            onClick={() => {
              // console.log(
              //   "burger menu clicked! The current value of show toggle is ",
              //   showToggleLinks
              // );
              setShowToggleLinks((current) => !current);
            }}
          >
            {showToggleLinks ? (
              <div className="closeIcon"> X</div>
            ) : (
              <IoMenu size={"1.5rem"} />
            )}
          </div>
          <Link
            className="noDecorationLink"
            onClick={() => {
              analyParamsCB({
                type: "click",
                pl: {
                  pn: location.pathname,
                  clk: "cartLinkHeader",
                  //field: JSON.stringify({ email }),
                },
              });
            }}
            to="/cart"
          >
            <div className="cartIconContainer">
              <IoCartOutline size={"1.5rem"} />
              {cart && cart.length > 0 && (
                <div className="itemCount"> {cart.length}</div>
              )}
            </div>
          </Link>
        </div>
        <div
          className={
            showToggleLinks ? "headerLinksHidden active" : "headerLinksHidden"
          }
        >
          <span
            className="noDecorationLink"
            onClick={() => {
              analyParamsCB({
                type: "click",
                pl: {
                  pn: location.pathname,
                  clk: "homeHiddenLinkHeader",
                  //field: JSON.stringify({ email }),
                },
              });
              setShowToggleLinks(false);
              navigate("/");
            }}
          >
            <div className="hiddenLinks">Home</div>
          </span>
          <span
            className="noDecorationLink"
            onClick={() => {
              analyParamsCB({
                type: "click",
                pl: {
                  pn: location.pathname,
                  clk: "cartHiddenLinkHeader",
                  //field: JSON.stringify({ email }),
                },
              });
              setShowToggleLinks(false);
              navigate("/cart");
            }}
          >
            <div className="hiddenLinks">Cart</div>
          </span>
          <span
            className="noDecorationLink"
            onClick={() => {
              analyParamsCB({
                type: "click",
                pl: {
                  pn: location.pathname,
                  clk: "accountHiddenLinkHeader",
                  //field: JSON.stringify({ email }),
                },
              });
              setShowToggleLinks(false);
              navigate("/account");
            }}
          >
            <div className="hiddenLinks">Account</div>
          </span>
        </div>
      </div>
    </div>
  );
}

export default Header;
