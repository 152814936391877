import { useEffect, useState } from "react";
import { useServerAPICalls } from "../../../hooks/useServerAPICalls";
import "./editProfileTab.css";

function EditProfileTab() {
  const [address, setAddress] = useState();
  const [name, setName] = useState("");
  const [address1, setAddress1] = useState("");
  const [address2, setAddress2] = useState("");
  const [city, setCity] = useState("");
  const [postCode, setPostCode] = useState("");
  const serverAPICallBack = useServerAPICalls("");

  const getAddressDetails = async () => {
    const data = await serverAPICallBack("get", "user/address");
    //console.log("The data received is ", data.data);
    setAddress(data.data);
  };

  useEffect(() => {
    if (address) {
      setName(address.name || "");
      setAddress1(address.address1 || "");
      setAddress2(address.address2 || "");
      setCity(address.city || "");
      setPostCode(address.postCode || "");
    }
  }, [address]);

  useEffect(() => {
    getAddressDetails();
  }, []);

  const onSaveHandler = async () => {
    const data = await serverAPICallBack("put", "user/address", {
      name,
      address1,
      address2,
      city,
      postCode,
    });
    //console.log("Address Updated");
    setAddress(data.data);
    alert("New Details Saved! ");
  };

  return (
    <div className="editProfileContainer">
      <div className="editProfileHeading">Edit Profile</div>
      <div className="editDetailsFormBox">
        <div className="editProfileRow">
          <div className="editProfileLabel">Name:</div>
          <input
            className="editProfileInput"
            value={name}
            onChange={(e) => {
              setName(e.target.value);
            }}
          />
        </div>
        <div className="editProfileRow">
          <div className="editProfileLabel">Address Line 1:</div>
          <input
            className="editProfileInput"
            value={address1}
            onChange={(e) => {
              setAddress1(e.target.value);
            }}
          />
        </div>
        <div className="editProfileRow">
          <div className="editProfileLabel">Address Line 2:</div>
          <input
            className="editProfileInput"
            value={address2}
            onChange={(e) => {
              setAddress2(e.target.value);
            }}
          />
        </div>
        <div className="editProfileRow">
          <div className="editProfileLabel">City/Town:</div>
          <input
            className="editProfileInput"
            value={city}
            onChange={(e) => {
              setCity(e.target.value);
            }}
          />
        </div>
        <div className="editProfileRow">
          <div className="editProfileLabel">PostCode/ZipCode:</div>
          <input
            className="editProfileInput"
            value={postCode}
            onChange={(e) => {
              setPostCode(e.target.value);
            }}
          />
        </div>
        <div className="editProfileBtn" onClick={() => onSaveHandler()}>
          Save
        </div>
      </div>
    </div>
  );
}

export default EditProfileTab;
