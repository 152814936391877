import { useEffect, useState } from "react";
import { createContext } from "react";

export const CartContext = createContext();

//Note : The way the cart context works is :
// 0) initalisation happens in the App.js where the local storage value is read by calling initialiseCartfromStorage from its own useEffect
// i) it passes the cart and setCart to components so that cart is manipulated directly by components
// ii) it has useEffect dependent on cart itself, which will update the localstorage value one every cart update

function CartContextProvider({ children }) {
  const [cart, setCart] = useState();

  const initialiseCartfromStorage = async () => {
    const cartItems = localStorage.getItem("cartItems");
    //console.log("The stored cart Items are ", cartItems);
    if (cartItems) {
      setCart(JSON.parse(cartItems));
    }
  };

  const updateCartStorage = async () => {
    //console.log("Updating cart in storage to ", cart);
    localStorage.setItem("cartItems", JSON.stringify(cart));
    // console.log(
    //   "The updated cart items are ",
    //   localStorage.getItem("cartItems")
    // );
  };

  useEffect(() => {
    // console.log(
    //   "The cart context value has changed and hence cart will be updated in storage too. Cart Value:",
    //   cart
    // );
    if (!cart) {
      // console.log(
      //   "Cart Context: In if useEffect do nothing as cart is not defined or null"
      // );
      // Since cart in memory is null or undefined then it means that its first time run and we need to initialise it
      initialiseCartfromStorage();
    } else {
      updateCartStorage();
    }
  }, [cart]); // (when loading this is step 2 )this runs after the useEffect below is run for the first run to help update it in the storage

  const value = {
    cart,
    setCart,
    initialiseCartfromStorage,
  };
  return <CartContext.Provider value={value}>{children}</CartContext.Provider>;
}

export default CartContextProvider;
