import "./orderConfirmation.css";

function OrderConfirmation() {
  return (
    <div className="orderConfirmationPageContainer">
      <div className="orderConfirmedHeading">
        Thank you! Your Order is placed .
        <div className="orderConfirmedSubHeading">
          We will get back to you within 24 hours with Confirmation Status
        </div>
        <div className="emailSentRow">
          We have sent an Order Received acknowlegment to your email address.
          Please check spam folder incase you haven't received it in your inbox
        </div>
        <div className="nextSteps">What happens next ?</div>
        <ul className="nextStepsBullets">
          <li>
            We will notify and let the merchants/sellers accept your order
          </li>
          <li>
            Once a merchant confirms, we will send you an email to let you know
          </li>
          <li>You can make the payment online using Credit or Debit card</li>
          <li>
            Your are eligible for 30 Day Free returns from the date of delivery
          </li>
        </ul>
      </div>
    </div>
  );
}

export default OrderConfirmation;
