import { useContext, useEffect, useState } from "react";
import { useServerAPICalls } from "../../../hooks/useServerAPICalls";
import { UserContext } from "../../../store/userContext";
import OrderDetail from "./OrderDetail";
import "./orderHistoryTab.css";
import OrdersList from "./OrdersList";

function OrderHistoryTab() {
  const [ordersList, setOrdersList] = useState([]);
  const [showOneOrderDetails, setShowOneOrderDetails] = useState("");
  const { user } = useContext(UserContext);
  const serverApiCallback = useServerAPICalls();

  const getOrdersList = async () => {
    const data = await serverApiCallback("get", "order");
    //console.log("Orders Data Received is ", data.data);
    setOrdersList(data.data);
  };

  useEffect(() => {
    getOrdersList();
  }, []);

  // either show all the orders or show just one order in detail
  return showOneOrderDetails === "" ? (
    <OrdersList
      setShowOneOrderDetails={setShowOneOrderDetails}
      ordersList={ordersList}
    />
  ) : (
    <OrderDetail
      setShowOneOrderDetails={setShowOneOrderDetails}
      showOneOrderDetails={showOneOrderDetails}
    />
  );
}

export default OrderHistoryTab;
