import "./category.css";
import { useLocation, useParams } from "react-router-dom";
import { useServerAPICalls } from "../../hooks/useServerAPICalls";
import { useContext, useEffect, useState } from "react";
import ProductBox from "../../components/productBox/ProductBox";
import BackButton from "../../components/ui/BackButton";
import DropDownButton from "../../components/ui/DropDownButton";
import { ShoesContext } from "../../store/shoesContext";
import CompactProductList from "../../components/compactProductsList/CompactProductList";
import { useAnlyParams } from "../../hooks/useAnlyParams";
import SignupModal from "../../components/modals/signupModal/SignupModal";

function Category() {
  const [prods, setProds] = useState([]);
  const [prodToShow, setProdToShow] = useState([]); // this is in case you have more than 50 products in category. May be you can use only this instead of using prod array
  const [prodLoadCounter, setProdLoadCounter] = useState(1);
  const [bottomHitAlready, setBottomHitAlready] = useState(false); // this is to track if btm of page hit once or not
  const [scrolledALittle, setscrolledALittle] = useState(false); // this is to track if they scrolled atleast a little bit

  const [nLModalVisible, setNLModalVisible] = useState(false);

  const [gender, setGender] = useState();
  const [colour, setColour] = useState();
  const [size, setSize] = useState();
  const [width, setWidth] = useState(window.innerWidth);
  const {
    genderCtx,
    setGenderCtx,
    sizeCtx,
    setSizeCtx,
    colourCtx,
    setColourCtx,
  } = useContext(ShoesContext);

  const location = useLocation();
  const analyParamsCB = useAnlyParams();

  const params = useParams();
  const categoryName = params.category;

  const serverApiCallback = useServerAPICalls();

  const showProductsListRandomly = (products) => {
    setProdToShow(
      products
        .map((prod) => ({ prod, sort: Math.random() }))
        .sort((a, b) => a.sort - b.sort)
        .map(({ prod }) => prod)
        .slice(0, prodLoadCounter * 50 - 1)
    );
  };
  const showProductsList = (products) => {
    // console.log(
    //   "Products",
    //   products,
    //   " and the product load counter is ",
    //   prodLoadCounter
    // );

    var prodList = gender
      ? products.filter((prod) => prod.filter1Value === gender)
      : products;

    //console.log("The prod List after gender set is ", prodList);

    //colour ? console.log("Colour", colour) : console.log("no colour");
    prodList =
      colour && colour !== "All"
        ? prodList.filter((prod) => prod.filter3Value === colour)
        : prodList;
    //console.log("The prod List after Colour set is ", prodList);

    prodList = size
      ? prodList.filter((prod) => prod.filter2Value === String(size))
      : prodList;
    setProdToShow(
      prodList.map((prod) => prod).slice(0, prodLoadCounter * 50 - 1)
    );
  };

  const getProducts = async () => {
    //console.log("Get Products called for Category ", categoryName);
    const data = await serverApiCallback(
      "get",
      "products/category/" + categoryName,
      {}
    );
    //console.log("The data returned is ", data.data);
    setProds(data.data);
    showProductsListRandomly(data.data);
  };

  // This useEffect is for loading the products everytime we hit the page bottom
  useEffect(() => {
    // check if its the first time page is loaded as well as max of 10 times
    if (prodLoadCounter > 1 && prodLoadCounter < 10) {
      // console.log(
      //   "Inside If, the prod load counter is currently set to",
      //   prodLoadCounter
      // );
      showProductsList(prods);
    }
  }, [prodLoadCounter]);

  // This useEffect is for loading the products everytime the filter is changed
  useEffect(() => {
    // console.log(
    //   "Filter Changed: Gender, Colour, Size are :",
    //   gender,
    //   " ",
    //   colour,
    //   " ",
    //   size
    // );

    showProductsList(prods);
    setGenderCtx(gender);
    setSizeCtx(size);
  }, [gender, colour, size, prods]); // everytime filter is changed or products are loaded make sure you change the products to show

  useEffect(() => {
    /*
 Three things happen in this function:
 a- Products are loaded if the prods array is empty so initialisation and also setting the screen back to Top via scrollTo. 
 b- Track Partial Scroll - Registering of appropriate functions so that analytics events are not registered on every scroll and is done only once. Hence two functions. 
 c- Track Scroll to Btm - Registering of appropriate functions so that analytics events are not registered on every scroll to BTM and is done only once. Hence two functions. 
 */

    if (prods.length <= 0) {
      getProducts();
      window.scrollTo(0, 0); // apparenttly this is needed otherwise the page may open a bit below based on where it was in the previous screen
    }
    const onScrollFirstTime = function () {
      //console.log("The scrollY value is", window.scrollY);

      if (
        window.scrollY > 0 &&
        window.innerHeight + window.scrollY >= document.body.offsetHeight
      ) {
        //console.log("you're at the bottom of the page, load more products");
        setProdLoadCounter((prodLoadCounter) => prodLoadCounter + 1);
        // note here if you try and read any state's value it will be the default value as React assumes nothing changes as the dependency of the useEffect is []

        if (!bottomHitAlready) {
          //console.log("Sending the bottom page event");
          analyParamsCB({
            type: "scrollBtm",
            pl: {
              pn: location.pathname,
              field: "categoryPage&&" + categoryName,
            },
          });
          setBottomHitAlready(true);
          setNLModalVisible(true); // show the modal when you hit the bottom
        }
      }
    };
    const onScrollSubs = function () {
      if (window.innerHeight + window.scrollY >= document.body.offsetHeight) {
        //console.log("you're at the bottom of the page, load more products");
        setProdLoadCounter((prodLoadCounter) => prodLoadCounter + 1);
        // note here if you try and read any state's value it will be the default value as React assumes nothing changes as the dependency of the useEffect is []
      }
    };

    const onScrollSomeFirstTime = function () {
      //console.log("The scrollY value is", window.scrollY);

      if (window.scrollY > 600) {
        if (!bottomHitAlready) {
          //console.log("Sending the bottom page event");
          analyParamsCB({
            type: "scrollSome",
            pl: {
              pn: location.pathname,
              field: "categoryPage&&" + categoryName,
            },
          });
          setscrolledALittle(true);
        }
      }
    };
    const onScrollSomeSubs = function () {
      /* do nothing */
    };

    if (!scrolledALittle) {
      // this is done because the event listeners hard code the variable value so true remains true even though the state might have changed
      window.addEventListener("scroll", onScrollSomeFirstTime);
    } else {
      window.addEventListener("scroll", onScrollSomeSubs);
    }

    if (!bottomHitAlready) {
      // this is done because the event listeners hard code the variable value so true remains true even though the state might have changed
      window.addEventListener("scroll", onScrollFirstTime);
    } else {
      window.addEventListener("scroll", onScrollSubs);
    }
    // the first time we use the stored value in genderCtx if any
    setGender(genderCtx);
    setSize(sizeCtx);

    return () => {
      window.removeEventListener("scroll", onScrollFirstTime);
      window.removeEventListener("scroll", onScrollSubs);
      window.removeEventListener("scroll", onScrollSomeFirstTime);
      window.removeEventListener("scroll", onScrollSomeSubs);
    };
  }, [bottomHitAlready, scrolledALittle]);

  useEffect(() => {
    const handleResizeWindow = () => setWidth(window.innerWidth);
    // subscribe to window resize event "onComponentDidMount"
    window.addEventListener("resize", handleResizeWindow);
    return () => {
      // unsubscribe "onComponentDestroy"
      window.removeEventListener("resize", handleResizeWindow);
    };
  }, []);

  return (
    <>
      {categoryName === "Womens Clothing" && width < 551 ? (
        <>
          {nLModalVisible && (
            <SignupModal
              setIsModalVisible={setNLModalVisible}
              modalPrimaryMsg={"Get £10 Off when you sign up to our Newsletter"}
              modalPrimaryBtnTxt={"Sign Me Up"}
            />
          )}
          <CompactProductList categoryName={categoryName} prods={prods} />
        </>
      ) : (
        <div className="categoryPageContainer">
          <BackButton />
          <div className="resultsContainer">
            <div className="categoryNameRow">Category : {categoryName}</div>
            {categoryName === "Shoes" && (
              <div className="prodFiltersRow">
                <span className="filterRowFilterText">FILTERS</span>
                <DropDownButton
                  values={["Men", "Women"]}
                  label={"Gender "}
                  selection={gender}
                  setSelection={setGender}
                />
                {/* <DropDownButton
              values={[
                4, 4.5, 5, 5.5, 6, 6.5, 7, 7.5, 8, 8.5, 9, 9.5, 10, 10.5, 11,
                11.5, 12,
              ]}
              label={"Size "}
              selection={size}
              setSelection={setSize}
            /> */}
                <DropDownButton
                  values={["Black", "White", "Grey", "Pink", "All"]}
                  label={"Colour "}
                  selection={colour}
                  setSelection={setColour}
                />
              </div>
            )}
            <div className="productGrid">
              {prods.length > 0 &&
                // below we are checking if category is Shoes & if so then we are showing filter and sorting randomly
                (categoryName === "Shoes"
                  ? prodToShow.map((prod, idx) => (
                      <ProductBox prod={prod} key={idx} />
                    ))
                  : prods.map((prod, idx) => (
                      <ProductBox prod={prod} key={idx} />
                    )))}
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Category;
