import HorizontalLine from "../../ui/HorizontalLine";
import "./orderDetail.css";

function OrderDetail({ setShowOneOrderDetails, showOneOrderDetails }) {
  return (
    <div className="orderDetailsContainer">
      <div
        className="showAllOrdersBtn"
        onClick={() => setShowOneOrderDetails("")}
      >
        Back to All Orders
      </div>
      <div className="orderIdHeading">Order Id : {showOneOrderDetails._id}</div>
      {showOneOrderDetails.items.map((item, idx) => (
        <div key={idx}>
          <div className="orderDetailBox">
            <div className="orderItemBox">
              <div className="itemNameRow">
                <img
                  className="itemImageBox"
                  src={
                    (process.env.NODE_ENV === "development"
                      ? "http://192.168.1.133:5566"
                      : "https://lotcheaper.com") +
                    "/uploads/prodImages/prod_" +
                    item.imgId +
                    "/" +
                    item.imgId +
                    "_1.jpg"
                  }
                />
                {item.name.slice(0, 200)}
              </div>
              <div className="itemPriceRow">
                Price: £{item.offerPrice.toLocaleString()}
              </div>
              <div className="itemPriceRow">Qty: {item.qty}</div>
              <div className="itemPriceRow">
                Total To Pay: £{" "}
                {Number(item.offerPrice * item.qty).toLocaleString()}
              </div>
              <div className="itemStatusRow">Status: {item.status}</div>
            </div>
          </div>
          <div className="orderDetaialsCancelRow">
            <span className="orderCancelBtn"> Cancel </span> (You can cancel
            after the order is confirmed)
          </div>
          <HorizontalLine />
        </div>
      ))}
    </div>
  );
}

export default OrderDetail;
