import { useContext } from "react";
import { UserContext } from "../../../store/userContext";
import "./logoutBtn.css";
function LogoutBtn() {
  const { setUser } = useContext(UserContext);
  const logoutHandler = () => {
    setUser("delete");
  };
  return (
    <div className="logoutBtnContainer">
      <div className="confirmationHeading">
        Are you sure you want to Logout ?
        <div className="logoutBtn" onClick={() => logoutHandler()}>
          Yes
        </div>
      </div>
    </div>
  );
}

export default LogoutBtn;
