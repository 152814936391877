import { useEffect, useState } from "react";
import "./inviteTab.css";
import copyIcon from "./pinkCopyIcon.png";
import signupIcon from "./signupIcon.png";
import highfiveIcon from "./highFivePeople.png";
import rewardIcon from "./rewardIcon.png";
import { useServerAPICalls } from "../../../hooks/useServerAPICalls";

function InviteTab() {
  const [copyLinkStatus, setCopyLinkStatus] = useState(false);
  const [inviterId, setInviterId] = useState("");
  const serverAPICallBack = useServerAPICalls();

  const getshareUrl = async () => {
    const data = await serverAPICallBack("get", "user/invite");
    //console.log("The url data shared is ", data.data);
    setInviterId(data.data);
  };
  useEffect(() => {
    getshareUrl();
  }, []);

  return (
    <div className="inviteContainer">
      <div className="inviteHeading">
        Invite Friends and Family
        <div>
          <img src={rewardIcon} className="inviteRewardsIcon" />
        </div>
      </div>
      <div className="inviteSubHeading">
        Invite to get £15 for you and £15 your mates
      </div>
      <div className="inviteStepsOuterContainer">
        <div className="inviteStepContainer">
          <img src={copyIcon} className="inviteStepsIcon" />
          <div className="inviteStepsHeadingRow">
            <div className="inviteNumberBubble"> 1</div>
            <div className="inviteWriteupHeading">
              Copy & Share your personal invitation link
            </div>
          </div>
          <div className="inviteLink">
            https://www.lotcheaper.com/invite/{inviterId}
          </div>
          <div
            className="copyLinkBtn"
            onClick={
              async () =>
                navigator.clipboard
                  .writeText("https://www.lotcheaper.com/invite/" + inviterId)
                  .then(() => setCopyLinkStatus(true))
              //setCopyLinkStatus(true)
            }
          >
            {copyLinkStatus ? "Copied !" : "Copy Link"}
          </div>
        </div>
        <div className="inviteStepContainer">
          <img src={signupIcon} className="inviteStepsIcon" />
          <div className="inviteStepsHeadingRow">
            <div className="inviteNumberBubble"> 2</div>
            <div className="inviteWriteupHeading">
              They sign up using your link & make their first Purchase
            </div>
          </div>
          <div className="inviteStepNormalWriteup">
            Once their first order is confirmed we will credit your accounts
            with £15 each to spend with us
            <br />
            <br />
            The first purchase can be of any amount(no minimum spend threshold)
          </div>
        </div>
        <div className="inviteStepContainer">
          <img src={highfiveIcon} className="inviteStepsIcon" />
          <div className="inviteStepsHeadingRow">
            <div className="inviteNumberBubble"> 3</div>
            <div className="inviteWriteupHeading">
              Shop with your £ 15 credit
            </div>
          </div>
          <div className="inviteStepNormalWriteup">
            We send you both an email to confirm the good news! <br />
            <br />
            Credit is Auto applied during checkout for your next purchase of £15
            <br />
            <br />
            Credit can be used only on www.lotcheaper.com with a minimum spend
            of £15
          </div>
        </div>
      </div>
    </div>
  );
}

export default InviteTab;
