import "./brandsRow.css";
import Marquee from "react-fast-marquee";

function BrandsRow() {
  return (
    <div className="brandsRowContainer">
      <Marquee speed={40}>
        <img
          style={{
            height: "80px",
            verticalAlign: "middle",
          }}
          src={
            (process.env.NODE_ENV === "development"
              ? "http://192.168.1.133:5566"
              : "https://lotcheaper.com") +
            "/uploads/brands/" +
            "Adidas-logo.webp"
          }
          alt={
            (process.env.NODE_ENV === "development"
              ? "http://192.168.1.133:5566"
              : "https://lotcheaper.com") +
            "/uploads/brands/" +
            "Adidas-logo.webp"
          }
        />

        <img
          style={{
            height: "50px",
            verticalAlign: "middle",
          }}
          src={
            (process.env.NODE_ENV === "development"
              ? "http://192.168.1.133:5566"
              : "https://lotcheaper.com") +
            "/uploads/brands/" +
            "Apple-Logo.png"
          }
          alt={
            (process.env.NODE_ENV === "development"
              ? "http://192.168.1.133:5566"
              : "https://lotcheaper.com") +
            "/uploads/brands/" +
            "Apple-Logo.png"
          }
        />

        <img
          style={{
            height: "80px",
            verticalAlign: "middle",
          }}
          src={
            (process.env.NODE_ENV === "development"
              ? "http://192.168.1.133:5566"
              : "https://lotcheaper.com") +
            "/uploads/brands/" +
            "emporioarmani.jpg"
          }
          alt={
            (process.env.NODE_ENV === "development"
              ? "http://192.168.1.133:5566"
              : "https://lotcheaper.com") +
            "uplaods/brands/" +
            "emporioarmani.jpg"
          }
        />
        <img
          style={{
            marginLeft: "25px",
            height: "50px",
            verticalAlign: "middle",
          }}
          src={
            (process.env.NODE_ENV === "development"
              ? "http://192.168.1.133:5566"
              : "https://lotcheaper.com") +
            "/uploads/brands/" +
            "samsung.png"
          }
          alt={
            (process.env.NODE_ENV === "development"
              ? "http://192.168.1.133:5566"
              : "https://lotcheaper.com") +
            "uplaods/brands/" +
            "samsung.png"
          }
        />
        <img
          style={{
            marginLeft: "25px",
            height: "40px",
            verticalAlign: "middle",
          }}
          src={
            (process.env.NODE_ENV === "development"
              ? "http://192.168.1.133:5566"
              : "https://lotcheaper.com") +
            "/uploads/brands/" +
            "nike.png"
          }
          alt={
            (process.env.NODE_ENV === "development"
              ? "http://192.168.1.133:5566"
              : "https://lotcheaper.com") +
            "uplaods/brands/" +
            "nike.png"
          }
        />
        <img
          style={{
            marginLeft: "25px",
            height: "40px",
            verticalAlign: "middle",
          }}
          src={
            (process.env.NODE_ENV === "development"
              ? "http://192.168.1.133:5566"
              : "https://lotcheaper.com") +
            "/uploads/brands/" +
            "boss.png"
          }
          alt={
            (process.env.NODE_ENV === "development"
              ? "http://192.168.1.133:5566"
              : "https://lotcheaper.com") +
            "uplaods/brands/" +
            "boss.png"
          }
        />
        <img
          style={{
            marginLeft: "25px",
            height: "40px",
            verticalAlign: "middle",
          }}
          src={
            (process.env.NODE_ENV === "development"
              ? "http://192.168.1.133:5566"
              : "https://lotcheaper.com") +
            "/uploads/brands/" +
            "google.png"
          }
          alt={
            (process.env.NODE_ENV === "development"
              ? "http://192.168.1.133:5566"
              : "https://lotcheaper.com") +
            "uplaods/brands/" +
            "google.png"
          }
        />
        <img
          style={{
            marginLeft: "25px",
            height: "80px",
            verticalAlign: "middle",
          }}
          src={
            (process.env.NODE_ENV === "development"
              ? "http://192.168.1.133:5566"
              : "https://lotcheaper.com") +
            "/uploads/brands/" +
            "chanel.png"
          }
          alt={
            (process.env.NODE_ENV === "development"
              ? "http://192.168.1.133:5566"
              : "https://lotcheaper.com") +
            "uplaods/brands/" +
            "chanel.png"
          }
        />
        <img
          style={{
            marginLeft: "25px",
            height: "50px",
            verticalAlign: "middle",
          }}
          src={
            (process.env.NODE_ENV === "development"
              ? "http://192.168.1.133:5566"
              : "https://lotcheaper.com") +
            "/uploads/brands/" +
            "bosch.png"
          }
          alt={
            (process.env.NODE_ENV === "development"
              ? "http://192.168.1.133:5566"
              : "https://lotcheaper.com") +
            "uplaods/brands/" +
            "bosch.png"
          }
        />
      </Marquee>
    </div>
  );
}
export default BrandsRow;
