import "./sellerproductDescriptionSection.css";
import { useContext, useRef, useState } from "react";
import { toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import HorizontalLine from "../../components/ui/HorizontalLine";
import { useAnlyParams } from "../../hooks/useAnlyParams";
import { CartContext } from "../../store/cartContext";

function SellerProductDesciptionSection({ prod }) {
  const [qty, setQty] = useState(1);
  const { cart, setCart } = useContext(CartContext);

  const navigate = useNavigate();
  const location = useLocation();
  const analyParamsCB = useAnlyParams();

  const addToCartHandler = () => {
    // console.log(
    //   "Add to cart called for ",
    //   prod.name,
    //   " with qty ",
    //   qty,
    //   "and prod_id",
    //   prod._id
    // );
    // console.log("The cart currently is ", cart); // the output puzzles me as first time it shows null as expected but second time onwards it shows the updated value of cart instead of previous value; even though the logging code is run prior to udpate of cart state
    // // may be above behaviour could be because of log taking time and update of value taking less time than that.

    //1/2 check if the product exits in the array
    var prodExists = false;
    if (cart && cart.length > 0) {
      prodExists =
        cart.map((item) => item._id).indexOf(prod._id) > -1 ? true : false;
    }
    //console.log("The prod Exits is ", prodExists);

    //2/2 if it exits then update the qty else add the item
    if (prodExists) {
      // update the qty
      setCart(
        cart.map((item) => {
          item._id === prod._id && (item.qty = Number(item.qty) + Number(qty));
          return item;
        })
      );
    } else {
      //insert the product
      setCart(cart ? [...cart, { ...prod, qty }] : [{ ...prod, qty }]);
    }

    analyParamsCB({
      type: "click",
      pl: {
        pn: location.pathname,
        clk: "addToCartProdPage",
        field: prod.name,
      },
    });

    toast.success("Added to Cart", {
      position: "bottom-center",
      autoClose: 1500,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
  };

  const myRef = useRef(null);

  const executeScroll = () => myRef.current.scrollIntoView();
  // run this function from an event handler or an effect to execute scroll

  return (
    prod.description && (
      <div className="prodDescContainer">
        <div className="prodNameHeader">{prod.name}</div>
        {
          <div className="prodDescRow">
            {prod.description.length > 200 ? (
              <>
                <span>{prod.description.slice(0, 200) + "... "}</span>
                <span
                  onClick={() => {
                    executeScroll();
                    analyParamsCB({
                      type: "click",
                      pl: {
                        pn: location.pathname,
                        clk: "moreLinkProdPage",
                        field: prod.name,
                      },
                    });
                  }}
                  style={{ color: "blue", cursor: "pointer" }}
                >
                  more
                </span>
              </>
            ) : (
              prod.description
            )}
          </div>
        }

        <HorizontalLine />
        <div className="prodDetailsOneRow">
          <div className="prodDetailslabel">{prod.feature1Name}:</div>
          {prod.feature1Value}
        </div>
        {prod.feature2Name && (
          <>
            <div className="prodDetailsOneRow">
              <div className="prodDetailslabel">{prod.feature2Name}:</div>
              {prod.feature2Value}
            </div>
          </>
        )}

        <div className="prodDetailsOneRow">
          <div className="prodDetailslabel">Price:</div>
          <span style={{ textDecoration: "line-through", color: "grey" }}>
            <span style={{ color: "red" }}>
              {" £ " + Number(prod.origPrice).toLocaleString() + " "}
            </span>
          </span>
        </div>
        <div className="prodDetailsOneRow">
          <div className="prodDetailslabel">Offer Price:</div>
          <strong style={{ color: "orange" }}>
            {"£ " + Number(prod.offerPrice).toLocaleString()}
          </strong>
        </div>
        <div className="prodDetailsOneRow">
          <div
            className="prodDetailslabel"
            id="savingsElementForScrollReference"
          >
            Savings:
          </div>
          <strong style={{ color: "green" }}>
            {"£ " + Number(prod.origPrice - prod.offerPrice).toLocaleString()}
          </strong>
        </div>
        <HorizontalLine />
        <div className="prodDescAddToCartContainer">
          <div className="qtyCartRow">
            <div
              className="qtyIncrDcrBtn"
              onClick={() => setQty((qty) => Math.max(qty - 1, 1))}
            >
              {" "}
              -{" "}
            </div>
            <input
              className="qtyInput"
              type="text"
              value={qty}
              onChange={(event) => {
                setQty(
                  event.target.value > 10
                    ? 10
                    : event.target.value === "0"
                    ? 1
                    : event.target.value
                );
              }}
              onBlur={(event) => {
                qty === "" && setQty(Number(1));
              }}
            />
            <div
              className="qtyIncrDcrBtn"
              onClick={() => setQty((qty) => Math.min(qty + 1, 10))}
            >
              {" "}
              +{" "}
            </div>
          </div>
          <div
            className="prodDescAddToCartBtn"
            onClick={() => addToCartHandler()}
          >
            Add To Cart
          </div>
        </div>
        <div className="prodDescNoteContainer">
          <div className="prodDescNotesBox" ref={myRef}>
            <div>30 Days Money Back Gurantee & Free Returns</div>
            <div>No upfront Payment requried - Pay after confirmation</div>
            <div>Get confirmation in 24 hours after placing order</div>
          </div>
        </div>
      </div>
    )
  );
}

export default SellerProductDesciptionSection;
