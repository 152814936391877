import {
  Link,
  UNSAFE_enhanceManualRouteObjects,
  useLocation,
} from "react-router-dom";
import "./signupModal.css";
import { useEffect, useState } from "react";
import { useAnlyParams } from "../../../hooks/useAnlyParams";
import { useServerAPICalls } from "../../../hooks/useServerAPICalls";

function SignupModal({
  setIsModalVisible,
  modalPrimaryMsg,
  modalPrimaryBtnTxt,
}) {
  const [email, setEmail] = useState("");
  const [showError, setShowError] = useState(false);

  const location = useLocation();
  const analyParamsCB = useAnlyParams();
  const serverApiCallback = useServerAPICalls();

  const toggleVisibility = () => {
    setIsModalVisible(false);
  };

  const suUserForNL = async () => {
    //console.log("Going to send order details");
    const data = await serverApiCallback("post", "user/nlsu", {
      email,
      page: location.pathname,
    });
    // console.log("The SU data received is ", data.data);
  };
  const signupHandler = () => {
    //console.log("Signup to NL by: ", email);
    //1 send the email address to userController to register it
    //2 send the button pressed event to the analytics controller
    if (email === "" || !email.includes("@") || !email.includes(".")) {
      //console.log("Error !!! ");
      setShowError(true);
      analyParamsCB({
        type: "signupNLFail",
        pl: {
          pn: location.pathname,
          field: email,
        },
      });
      return;
    }
    analyParamsCB({
      type: "signupNLSuccess",
      pl: {
        pn: location.pathname,
        field: email,
      },
    });
    suUserForNL();
    toggleVisibility();
  };

  return (
    <div
      className="suModaldarkBG"
      onClick={() => {
        analyParamsCB({
          type: "signupNLClosed",
          pl: {
            pn: location.pathname,
            clk: "backgroundClick",
          },
        });
        toggleVisibility();
      }}
    >
      <div
        className="suModalContainerBox"
        onClick={(e) => {
          e.stopPropagation();
          // toggleVisibility();
        }}
      >
        <div className="suModalCloseBtnRow">
          <span
            className="suModalCloseBtn"
            onClick={() => {
              analyParamsCB({
                type: "signupNLClosed",
                pl: {
                  pn: location.pathname,
                  clk: "rightCloseBtn",
                },
              });
              toggleVisibility();
            }}
          >
            x
          </span>
        </div>

        <div className="suModalDialogBox">
          {modalPrimaryMsg}
          <input
            className="suModalInput"
            placeholder={"Enter Your Email"}
            onChange={(e) => setEmail(e.target.value)}
            type="email"
          />
          {showError && (
            <div className="suModalErrorLine">
              Please Enter Correct Email Address
            </div>
          )}
          <div className="suModalActionRow">
            <span className="suModalSUBtn" onClick={() => signupHandler()}>
              {modalPrimaryBtnTxt}
            </span>
          </div>
          <div className="suModalAgreeTCLine">
            By signing up you agree to receiving marketing communication and{" "}
            <Link
              style={{ textDecoration: "none", color: "blue" }}
              to={"/terms"}
              onClick={() => {
                analyParamsCB({
                  type: "click",
                  pl: {
                    pn: location.pathname,
                    clk: "Terms",
                  },
                });
              }}
            >
              Terms & Conditions
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}
export default SignupModal;
