import "./productDetails.css";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import BackButton from "../../components/ui/BackButton";
import { useEffect, useRef } from "react";
import { useServerAPICalls } from "../../hooks/useServerAPICalls";
import { useState } from "react";
import ProductDesciptionSection from "../../components/productDetails/ProductDesciptionSection";
import { useAnlyParams } from "../../hooks/useAnlyParams";
import CombineBuyProductDesciptionSection from "../../components/productDetails/CombineBuyProductDesciptionSection";

function ProductDetails() {
  const [prod, setProd] = useState();
  const [mainImg, setMainImg] = useState();
  const params = useParams();
  const navigate = useNavigate();

  const serverApiCallback = useServerAPICalls();
  const location = useLocation();
  const analyParamsCB = useAnlyParams();

  const getProductDetails = async () => {
    //console.log("Get Product details for Product ", params.id);
    const data = await serverApiCallback("get", "products/" + params.id, {});
    //console.log("The data returned is ", data.data);
    if (data.isError) {
      //console.log("Error: Error is", data.msg);
      navigate("/");
    } else {
      setProd(data.data);
    }
  };

  const setMainImgHanlder = (img) => {
    // console.log("Set Main Image to ", img);
    setMainImg(img);
  };

  useEffect(() => {
    getProductDetails();
    window.scrollTo(0, 0); // apparenttly this is needed otherwise the page may open a bit below based on where it was in the previous screen
  }, [params.id]);

  useEffect(() => {
    prod &&
      setMainImg(
        (process.env.NODE_ENV === "development"
          ? "http://192.168.1.133:5566"
          : "https://comebuyin.com") +
          "/uploads/prodImages/prod_" +
          prod.productId +
          "/" +
          prod.productId +
          "_1.jpg"
      );
  }, [prod]);

  const myRef = useRef(null);

  const executeScrollToWtIsComebuyin = () => myRef.current.scrollIntoView();
  // run this function from an event handler or an effect to execute scroll

  return (
    <div className="productDetailsPageContainer">
      <BackButton />
      {prod && (
        <div className="productMainContainer">
          <div className="productImageAndDescBox">
            <div className="prodDescDiscountAmt">
              {"£ " + Number(prod.origPrice - prod.offerPrice).toLocaleString()}{" "}
              Off
            </div>
            <img src={mainImg} className="mainProdImage" />
            <div className="bottomImgsContainer">
              {[...Array(prod.images_cnt ? prod.images_cnt : 4)].map(
                (item, idx) => (
                  <div
                    key={idx}
                    className="bottomImgbox"
                    onClick={() => {
                      setMainImgHanlder(
                        (process.env.NODE_ENV === "development"
                          ? "http://192.168.1.133:5566"
                          : "https://comebuyin.com") +
                          "/uploads/prodImages/prod_" +
                          prod.productId +
                          "/" +
                          prod.productId +
                          "_" +
                          (idx + 1) +
                          ".jpg"
                      );
                      analyParamsCB({
                        type: "click",
                        pl: {
                          pn: location.pathname,
                          clk: "prodBtmImg",
                          field: "img" + (idx + 1),
                        },
                      });
                    }}
                  >
                    <img
                      className="bottomImg"
                      id={"btmImg" + idx}
                      src={
                        prod
                          ? (process.env.NODE_ENV === "development"
                              ? "http://192.168.1.133:5566"
                              : "https://comebuyin.com") +
                            "/uploads/prodImages/prod_" +
                            prod.productId +
                            "/" +
                            prod.productId +
                            "_" +
                            (idx + 1) +
                            ".jpg"
                          : ""
                      }
                    />
                  </div>
                )
              )}
            </div>
            <div className="imgBottoHelpText">click to select </div>
          </div>
          <div className="productImageAndDescBox">
            {prod.cbdeals &&
            Date.parse(prod.cbdeals.cbExpiryTime) - Date.now() > 0 ? (
              <CombineBuyProductDesciptionSection
                prod={prod}
                executeScrollToWtIsComebuyin={executeScrollToWtIsComebuyin}
              />
            ) : (
              <ProductDesciptionSection prod={prod} />
            )}
          </div>
          <div id="productLongDescriptionSection">
            <h4> Product Details </h4>
            {prod && <div>{prod.description}</div>}
          </div>
          {prod.cbdeals &&
            Date.parse(prod.cbdeals.cbExpiryTime) - Date.now() > 0 && (
              <div className="prodDetailsWtIsCombineBuyContainer" ref={myRef}>
                <div className="prodDetailsWtIsComineDealHeader">
                  What is Combine Buy Deal ?
                </div>
                <div>
                  You buy with others and get huge discounts. Only if minimum
                  number of orders are received within specified time the deal
                  is confirmed. You are not charged before the confirmation.{" "}
                  <br />
                  Tip: Combine Deals are more environment friendly
                </div>
              </div>
            )}
        </div>
      )}
    </div>
  );
}

export default ProductDetails;
