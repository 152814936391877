import Categories from "../../components/categoriesGrid/Categories";
import BackButton from "../../components/ui/BackButton";
import "./allCategories.css";

function AllCategories() {
  return (
    <div className="allCategoriesPageContainer">
      <BackButton />
      <Categories dontShowAllCatBtn={true} />
    </div>
  );
}
export default AllCategories;
