import { useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { useLocation, useNavigate } from "react-router-dom";
import { useAnlyParams } from "../../hooks/useAnlyParams";
import { useServerAPICalls } from "../../hooks/useServerAPICalls";
import "./passwordInput.css";
function PasswordInput({ userId }) {
  const [code, setCode] = useState();
  const [password, setPassword] = useState();
  const [confirm, setConfirm] = useState();
  const [error, setError] = useState();
  const [captcha, setCaptcha] = useState(null);

  function onCaptchaVerification(value) {
    //console.log("Captcha value:", value);
    setCaptcha(value);
  }

  const location = useLocation();
  const navigate = useNavigate();
  const analyParamsCB = useAnlyParams();
  const serverApiCallback = useServerAPICalls();

  const resetEmailCheck = async () => {
    if (!code) {
      setError("Error! Please Enter Reset Code");
      return;
    }
    if (!password) {
      setError("Error! Please enter New Password");
      return;
    }
    if (!confirm) {
      setError("Error! Please Confirm New Password");
      return;
    }
    if (confirm !== password) {
      setError("Error!  Passwords entered don't match");
      return;
    }
    if (!captcha) {
      setError("Error!  Please tick Captcha");
      return;
    }
    const data = await serverApiCallback("put", "user/resetpw", {
      id: userId,
      pass: password,
      captcha,
      resetCode: code,
    });
    if (data.isError) {
      setError("Error!  Something went wrong! Please try again");
      return;
    }
    navigate("/account");
  };
  return (
    <div className="resetPasswordContainer">
      <div className="loginHeader">Reset Password</div>
      <div className="resetPasswordInstruction">
        Please enter the Reset Code sent on your email address
      </div>

      <div className="signupDetailsRow">
        <div className="signupRowLabel">Reset Code</div>
        <input
          className="signupRowInput"
          type="email"
          onChange={(e) => setCode(e.target.value)}
        />
      </div>

      <div className="signupDetailsRow">
        <div className="signupRowLabel">New Password</div>
        <input
          className="signupRowInput"
          type="password"
          onChange={(e) => setPassword(e.target.value)}
        />
      </div>
      <div className="signupDetailsRow">
        <div className="signupRowLabel">Confirm Password</div>
        <input
          className="signupRowInput"
          type="password"
          onChange={(e) => setConfirm(e.target.value)}
        />
      </div>
      {error && <div className="resetPassErrorRow"> {error}</div>}
      <div className="signupCaptchaBox">
        <ReCAPTCHA
          //sitekey="6LdvwIckAAAAACvnmmU2nv9SjTpjebRw2dzNT6Mb"
          sitekey="6Ldueu0kAAAAALNNOmehsaJc-Ftfh1IoqhvvzTNZ"
          onChange={onCaptchaVerification}
        />
      </div>
      <div className="nextBtnRow">
        <div
          className="singupBtn"
          onClick={() => {
            analyParamsCB({
              type: "click",
              pl: {
                pn: location.pathname,
                clk: "nextBtnPwReset",
                /*field: email*/
              },
            });
            resetEmailCheck();
          }}
        >
          Reset
        </div>
      </div>
    </div>
  );
}
export default PasswordInput;
