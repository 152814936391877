import axios from "axios";
import { useContext } from "react";
import { UserContext } from "../store/userContext";
// import { useContext } from "react";
// import { AuthContext } from "../store/auth-context";
// import { ServerContext } from "../store/server-context";

export const useServerAPICalls = () => {
  //   const { config } = useContext(AuthContext);
  //   const { serverAddress } = useContext(ServerContext);
  const { user } = useContext(UserContext);
  const config = {
    "Content-Type": "application/json",
    headers: {
      Authorization: user && user !== "delete" ? `Bearer ${user.token}` : "",
    },
  };

  // I am not sure how the environment gets sets here ; but my guess is if its run with build then its production by default otherwise it development
  const serverAddress =
    process.env.NODE_ENV === "development"
      ? "http://192.168.1.133:5566/api/"
      : "https://comebuyin.com/api/";

  //axios.defaults.baseURL = serverAddress;

  const makeAPICallback = async (callType, route, body) => {
    // console.log(
    //   "Making the API call of type: ",
    //   callType,
    //   " and route: ",
    //   route
    //   /* "and body is ",
    //   body */ // Commenting because we dont want to show the values in the console to user
    // );

    var data;

    try {
      if (callType === "get") {
        //console.log("Making a GET Request");
        data = await axios.get(serverAddress + route, config);
        //console.log("data received is ", data.data);
      } else if (callType === "post") {
        //console.log("Making a POST Request: ", server + route);
        data = await axios.post(serverAddress + route, body, config);
      } else if (callType === "put") {
        //console.log("Making a PUT Request: ", server + route);
        data = await axios.put(serverAddress + route, body, config);
      }
    } catch (err) {
      console.log("Error is", err);
      return { msg: err.message, isError: true };
    }
    return data;
  };
  return makeAPICallback;
};
