import { useState } from "react";
import "./dropDownButton.css";

function DropDownButton({ label, values, selection, setSelection }) {
  const [showOptions, setShowOptions] = useState(false);
  return (
    <div className="filtersContainer">
      {label}
      <span
        className="filterSelectBox"
        onClick={() => {
          setShowOptions((value) => !value);
        }}
      >
        {selection ? selection : "-select-"}
      </span>
      <div
        className={
          showOptions
            ? "filterSelectOptionColumn showSelectionColumn"
            : "filterSelectOptionColumn"
        }
      >
        {values.length > 0 &&
          values.map((value, idx) => (
            <div
              className="fitlerOption"
              key={idx}
              onClick={() => {
                setSelection(value);
                setShowOptions((value) => !value);
              }}
            >
              {value}
            </div>
          ))}
      </div>
    </div>
  );
}
export default DropDownButton;
