import { useEffect, useState } from "react";
import { createContext } from "react";

export const CookieAccptContext = createContext();

//Note : The way the cart context works is :
// 0) initalisation happens in the App.js where the local storage value is read by calling initialisecookieAccptfromStorage in the useEffect if App.js
// i) it passes the cart and setCart to components so that cart is manipulated directly by components
// ii) it has useEffect dependent on cart itself, which will update the localstorage value one every time key is updated

function CookieAccptContextProvider({ children }) {
  const [cookieAccpt, setCookieAccpt] = useState(false);

  // below function serves two purposes:
  //a) initialise the context variable when the page is loaded/refreshed with what is there in loc storage
  //b) return the value of cookieAccpt as well used when context is not loaded and in parallel the context value is accessed

  const initialiseCookieAccptfromStorage = async () => {
    const cookieAccptVar = localStorage.getItem("cookieAccpt");
    //console.log("The stored cookieAccpt  is ", cookieAccptVar);
    if (cookieAccptVar) {
      setCookieAccpt(JSON.parse(cookieAccptVar));
      return cookieAccptVar;
    }
    return false;
  };

  const updatecookieAccpt = async () => {
    //console.log("Updating ss cookieAccpt in storage to ", cookieAccpt);
    localStorage.setItem("cookieAccpt", JSON.stringify(cookieAccpt));
  };

  useEffect(() => {
    if (cookieAccpt) {
      updatecookieAccpt();
    } else {
      initialiseCookieAccptfromStorage(); // must be the first time after loading, so it must be initialised
    }
  }, [cookieAccpt]); // everytime the session device key state is updated; we need to update it in the local storage as well

  const value = {
    cookieAccpt,
    setCookieAccpt,
    initialiseCookieAccptfromStorage,
  };
  return (
    <CookieAccptContext.Provider value={value}>
      {children}
    </CookieAccptContext.Provider>
  );
}

export default CookieAccptContextProvider;
