import BackButton from "../../components/ui/BackButton";
import HorizontalLine from "../../components/ui/HorizontalLine";
import "./wtIsCombineBuy.css";
function WtisCombineBuy() {
  return (
    <div className="wtIsCombineBuyPageContainer">
      <BackButton />
      <h1 className="wtIsCombineHeading">Why Comebine Buy?</h1>
      <div className="wtIsCombineValuePropsRow">
        <div className="wtIsCombineBenefit">Never Pay Full Price Again</div>
        <div className="wtIsCombineBenefit">Express Delivery</div>
        <div className="wtIsCombineBenefit">Pay Safely</div>
      </div>
      <HorizontalLine />
      <h2>FAQs</h2>
      <div className="wtIsCombineFAQOneRow">
        <div className="wtIsCombineRowQuestion">What is Combine Deal ?</div>
        <div className="wtIsCombineRowAnswer">
          It’s a new way of buying where you buy good quality products at
          meaningful discounts. Every transaction has a minimum orders goal
          because of which they are sold cheaper, so you buy the same products
          you want but at a meaningful discount
        </div>
      </div>
      <div className="wtIsCombineFAQOneRow">
        <div className="wtIsCombineRowQuestion">Do I pay upfront ? </div>
        <div className="wtIsCombineRowAnswer">
          We don’t charge you till a deal is complete. We simply take an
          authorization from your chosen payment method when you place an order.
        </div>
      </div>
      <div className="wtIsCombineFAQOneRow">
        <div className="wtIsCombineRowQuestion">Is it safe ?</div>
        <div className="wtIsCombineRowAnswer">
          Yes, absolutely. We use trusted third party like PayPal to process the
          payment to make sure you are protected with best in class payment
          service
        </div>
      </div>
      <div className="wtIsCombineFAQOneRow">
        <div className="wtIsCombineRowQuestion">Can I return my products ?</div>
        <div className="wtIsCombineRowAnswer">
          Yes, absolutely. Each product is covered with a 30 days return policy
        </div>
      </div>
      <div className="wtIsCombineFAQOneRow">
        <div className="wtIsCombineRowQuestion">
          Why should I buy with Combine Buy Deal ?
        </div>
        <div className="wtIsCombineRowAnswer">
          You get many benefits : <br />
          <ul>
            <li>
              Meaningful discount on the same product <br />
            </li>
            <li>Combine Buy deals are also more planet friendly</li>
          </ul>
        </div>
      </div>
    </div>
  );
}
export default WtisCombineBuy;
