import { useEffect, useState } from "react";
import "./cartPRiceTotCols.css";

function CartPriceTotCols({ item, qty }) {
  const [finalOrigPrice, setFinalOrigPrice] = useState();
  const [finalOfferPrice, setFinalOfferPrice] = useState();

  useEffect(() => {
    setFinalOfferPrice(
      item.type === "combine"
        ? Number(item.cbdeals.cbPrice).toLocaleString()
        : item.sizeSelected
        ? item.sizes.filter((i) => i.size === item.sizeSelected)[0].offerPrice
        : Number(item.offerPrice).toLocaleString()
    );
    setFinalOrigPrice(
      item.sizeSelected
        ? item.sizes.filter((i) => i.size === item.sizeSelected)[0].origPrice
        : Number(item.origPrice).toLocaleString()
    );
  }, [item]);

  return (
    <>
      <div className="priceCol">
        <div className="priceLabel">Price </div>£{finalOfferPrice}
        <div>
          (<span className="origPriceRow">£ {finalOrigPrice}</span>)
        </div>
      </div>
      <div className="totalCol">
        <div className="totalLabel">Total </div>
        <div>£ {Number(finalOfferPrice * qty).toLocaleString()}</div>

        <div className="cartSavingsItems">
          Savings: £
          {(
            Number(
              item.type === "combine"
                ? Number(finalOrigPrice - item.cbdeals.cbPrice)
                : Number(finalOrigPrice - finalOfferPrice)
            ) * qty
          ).toLocaleString()}
        </div>
      </div>
    </>
  );
}
export default CartPriceTotCols;
