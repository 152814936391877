import { useLocation, useNavigate } from "react-router-dom";
import { useAnlyParams } from "../../hooks/useAnlyParams";
import "./backButton.css";

function BackButton() {
  const navigate = useNavigate();
  const analyParamsCB = useAnlyParams();
  const location = useLocation();
  return (
    <div
      className="backBtn"
      onClick={() => {
        analyParamsCB({
          type: "click",
          pl: {
            pn: location.pathname,
            clk: "back",
            field: "back",
          },
        });
        navigate(-1);
      }}
    >
      Back
    </div>
  );
}

export default BackButton;
