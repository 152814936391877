import "./productSizeOptionsBox.css";

function ProductSizeOptionBox({ isSelected, setSelectedSize, size }) {
  const sizeSelectHandler = () => {
    setSelectedSize(size);
  };
  return (
    <div
      className={
        isSelected
          ? "productSizeBoxOptionContainerSelected"
          : "productSizeBoxOptionContainer"
      }
      onClick={() => sizeSelectHandler()}
    >
      <div
        className={
          isSelected
            ? "prodSizeBoxOptionsItemSelected"
            : "prodSizeBoxOptionsItem"
        }
      >
        {size}
      </div>
    </div>
  );
}
export default ProductSizeOptionBox;
