import EmailInput from "../../components/resetPassword/EmailInput";
import "./resetPassword.css";
import BarLoader from "react-spinners/BarLoader";
import { useEffect, useState } from "react";
import PasswordInput from "../../components/resetPassword/PasswordInput";

function ResetPassword() {
  const [emailProvided, setEmailProvided] = useState(false);
  const [userId, setUserId] = useState();
  const [resetCode, setResetCode] = useState();
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (emailProvided) {
      setLoading(true);
      setTimeout(() => setLoading(false), 3000);
    }
  }, [emailProvided]);
  return (
    <div className="resetPageContainer">
      {!emailProvided ? (
        <EmailInput
          setEmailProvided={setEmailProvided}
          setUserId={setUserId}
          setResetCode={setResetCode}
        />
      ) : loading ? (
        <div className="loaderContainer">
          <BarLoader color="orange" loading={loading} />
        </div>
      ) : (
        <PasswordInput userId={userId} resetCode={resetCode} />
      )}
    </div>
  );
}
export default ResetPassword;
