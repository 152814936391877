import "./buyerDemoFeaturedProducts.css";
import { prodData } from "../../screens/demoTool/sellerHome/SellerHome";
import SellerProductBox from "./SellerProductBox";
import { useEffect, useState } from "react";

function BuyerDemoFeaturedProducts({ sellerId }) {
  const [sellersProds, setSellersProds] = useState([]);
  const filterSellersProds = () => {
    setSellersProds(prodData.filter((p) => p.sellerId === sellerId));
  };

  useEffect(() => {
    console.log("the seller id is ", sellerId);

    filterSellersProds();
  }, []);

  return (
    <div className="sellerHomeFeaturedProdsContainer">
      {sellersProds.length > 0 &&
        // below we are checking if category is Shoes & if so then we are showing filter and sorting randomly
        [sellersProds[5], sellersProds[9], sellersProds[13]].map(
          (prod, idx) => <SellerProductBox prod={prod} key={idx} />
        )}
    </div>
  );
}
export default BuyerDemoFeaturedProducts;
