import { useEffect, useState } from "react";
import { createContext } from "react";

export const SSDevKeyContext = createContext();

//Note : The way the cart context works is :
// 0) initalisation happens in the App.js where the local storage value is read by calling initialiseSsDevKeyfromStorage in the useEffect if App.js
// i) it passes the cart and setCart to components so that cart is manipulated directly by components
// ii) it has useEffect dependent on cart itself, which will update the localstorage value one every time key is updated

function SSDevKeyContextProvider({ children }) {
  const [ssDevKey, setSsDevKey] = useState(null);

  // below function serves two purposes:
  //a) initialise the context variable when the page is loaded/refreshed with what is there in loc storage
  //b) return the value of ssdevkey as well used when context is not loaded and in parallel the context value is accessed

  const initialiseSsDevKeyfromStorage = async () => {
    const ssDevKeyVar = localStorage.getItem("ssDevKey");
    // console.log("The stored ssDevKey  is ", ssDevKeyVar);
    if (ssDevKeyVar) {
      setSsDevKey(JSON.parse(ssDevKeyVar));
      return ssDevKeyVar;
    }
    return null;
  };

  const updateSsDevKey = async () => {
    //console.log("Updating ss Dev key in storage");
    localStorage.setItem("ssDevKey", JSON.stringify(ssDevKey));
  };

  useEffect(() => {
    if (ssDevKey) {
      updateSsDevKey();
    } else {
      initialiseSsDevKeyfromStorage(); // must be the first time after loading, so it must be initialised
    }
  }, [ssDevKey]); // everytime the session device key state is updated; we need to update it in the local storage as well

  const value = {
    ssDevKey,
    setSsDevKey,
    initialiseSsDevKeyfromStorage,
  };
  return (
    <SSDevKeyContext.Provider value={value}>
      {children}
    </SSDevKeyContext.Provider>
  );
}

export default SSDevKeyContextProvider;
